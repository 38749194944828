<div class="contained">

    <div class="privacy-buttons mt-20 mb-20">
        <a [routerLink]="['/']" class="aw-btn btn-main text-uppercase">Home</a>
        <a [routerLink]="['/gamesurv/sign-up']" class="aw-btn btn-right text-uppercase">Sign up</a>
        <a [routerLink]="['/gamesurv/sign-in']" class="aw-btn btn-right text-uppercase">Sign in</a>
    </div>

    <div class="privacy-card mb-20">
        <h1 class="page-heading">Privacy policy</h1>
        <ol>
            <li class="header">
                TERMS OF USE
            </li>
            <p>By downloading, browsing, accessing or using (<b>“Game Surv”</b>) on any device, you agree to be
                bound
                by these Terms and Conditions of Use and use of your personal information as described more fully below.
                We reserve the right to amend these Terms and Conditions of Use at
                any
                time. If you disagree with any of these Terms and Conditions of Use, you must immediately
                discontinue
                your access to the Game Surv and your use of the services offered on Game Surv. Continued use of Game
                Surv will constitute acceptance of these Terms and Conditions of Use, as may be amended from
                time
                to time.</p>
            <li class="header">
                DEFINITIONS
            </li>
            <p>In these Terms and Conditions of Use, the following terms shall have the following meanings, except where
                the
                context otherwise requires:</p>
            <p><b>"Account"</b> means an account created by a User on Game Surv.
            </p>
            <p><b>"Privacy Policy"</b> means the privacy policy set out in this document.</p>
            <p><b>"Register"</b> means to create an Account on Game Surv and
                <b>"Registration"</b> means the act of creating such an Account.
            </p>
            <p><b>"Services"</b> means all the services provided by Neshorn Technologies via Game Surv to
                users.</p>
            <p><b>"Users"</b> means any person or entity making use of any features affiliated with Game Surv.</p>
            <p><b>“Personal Data”</b> means data about a living individual who can be identified from those data (or
                from those and other information either in our possession or likely to come into our possession).</p>
            <p><b>“Usage Data”</b> means data that is collected automatically either generated by the use of the Service
                or from the Service infrastructure itself (for example, the duration of a page visit).
            </p>

            <li class="header">
                GENERAL APPLICABILITY
                <ol class="inner">
                    <li><i>Applicability of terms and conditions</i>: The use of any services affiliated with Game Surv
                        are subject to these Terms and Conditions of Use.</li>
                    <li><i>Prevention on use</i>: We reserve the right to prevent individuals from using Game Surv and
                        any services affiliated with Game Surv.</li>
                    <li><i>Equipment and Networks</i>: The provision of the services and Game Surv does not include the
                        provision of a mobile telephone or handheld device or other necessary equipment to access Game
                        Surv or use of its services. To use Game Surv or Services, you will require Internet
                        connectivity and appropriate telecommunication links. You acknowledge that the terms of
                        agreement with your respective mobile network provider will continue to apply when using Game
                        Surv. As a result, you may be charged by the Mobile Provider for access to network connection
                        services for the duration of the connection while accessing Game Surv or any such third party
                        charges as may arise. You accept responsibility for any such charges that may arise.</li>
                    <li><i>Permission to use Game Surv</i>: If you are not the bill payer for the mobile telephone or
                        handheld device being used to access Game Surv, you will be assumed to have received permission
                        from the bill payer for using Game Surv.</li>
                    <li><i>License to Use Material</i>: By submitting any text or images (including photographs) via
                        Game Surv Application, you represent that you are the owner of the material, or have proper
                        authorization from the owner of the material to use, reproduce and distribute it. You hereby
                        grant us a worldwide, royalty-free, non-exclusive license to use the material to promote any
                        products or services.</li>
                </ol>
            </li>

            <li class="header">
                LOCATION ALERTS AND NOTIFICATIONS
                <ol class="inner">
                    <li>You agree to receive pre-programmed notifications (“Location Alerts”) on Game Surv if you have
                        turned on locational services on your mobile telephone or other handheld devices.</li>
                </ol>
            </li>

            <li class="header">
                YOUR OBLIGATIONS
                <ol class="inner">
                    <li><i>Accurate information</i>: You warrant that all information provided on Registration and
                        contained as part of your Account is true, complete and accurate and that you will promptly
                        inform us of any changes to such information by updating the information in your Account.</li>
                    <li><i>Content on Game Surv</i>: It is your responsibility to ensure that any information available
                        through Game Surv or the Services meet your specific requirements.</li>
                    <li><i>Prohibitions in relation to usage of Game Surv or its affiliates</i>: Without limitation, you
                        undertake not to use or permit anyone else to use the Services or Game Surv:
                        <ol>
                            <li>to send or receive any material which is not civil;</li>
                            <li>to send or receive any material which is threatening, grossly offensive, of an indecent,
                                obscene or menacing character, blasphemous or defamatory of any person, in contempt of
                                court or in breach of confidence, copyright, rights of personality, publicity or privacy
                                or any other third party rights;</li>
                            <li>to send or receive any material for which you have not obtained all necessary licenses
                                and/or approvals (from us or third parties); or which constitutes or encourages conduct
                                that would be considered a criminal offence, give rise to civil liability, or otherwise
                                be contrary to the law of or infringe the rights of any third party in any country in
                                the world;</li>
                            <li>to send or receive any material which is technically harmful (including computer
                                viruses, logic bombs, Trojan horses, worms, harmful components, corrupted data or other
                                malicious software or harmful data);
                            </li>
                            <li>to cause annoyance, inconvenience or needless anxiety;</li>
                            <li>to intercept or attempt to intercept any communications transmitted by way of a
                                telecommunications system;</li>
                            <li>for a purpose other than which we have designed them or intended them to be used;
                            </li>
                            <li>for any fraudulent purpose;</li>
                            <li>other than in conformance with accepted Internet practices and practices of any
                                connected networks;</li>
                            <li>in any way which is calculated to incite hatred against any ethnic, religious or any
                                other minority or is otherwise calculated to adversely affect any individual, group or
                                entity.</li>
                        </ol>
                    </li>
                    <li><i>Prohibitions in relation to usage of Game Surv</i>: Without limitation, you further
                        undertake
                        not to
                        or permit anyone else to:
                        <ol>
                            <li>resell any Game Surv products without prior knowledge by Game Surv;</li>
                            <li>furnish false data including false names, addresses and contact details and fraudulently
                                use credit/debit card numbers;</li>
                            <li>attempt to circumvent our security or network including to access data not intended for
                                you, log into a server or account you are not expressly authorised to access, or probe
                                the security of other networks (such as running a port scan);</li>
                            <li>execute any form of network monitoring which will intercept data not intended for you;
                            </li>
                            <li>enter into fraudulent interactions or transactions with us or a Merchant (including
                                interacting or transacting purportedly on behalf of a third party where you have no
                                authority to bind that third party or you are pretending to be a third party);</li>
                            <li>extract data from or hack into Game Surv;</li>
                            <li>use Game Surv in breach of these Terms and Conditions of Use;</li>
                            <li>engage in any unlawful activity in connection with the use of Game Surv;
                            </li>
                            <li>engage in any conduct which, in our exclusive reasonable opinion, restricts or inhibits
                                any other customer from properly using or enjoying Game Surv or its affiliated programs.
                            </li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li class="header">
                RULES ABOUT USE OF THE SERVICE AND THE GAME SURV
                <ol class="inner">
                    <li>We will use reasonable endeavours to correct any errors or omissions as soon as practicable
                        after being notified of them. However, we do not guarantee that Game Surv will be free of
                        faults, and we do not accept liability for any such faults, errors or omissions. In the event
                        that you are of the opinion that there are any such error, fault or omission, you should report
                        it by contacting us at <a href="mailto:gamesurv@neshorntech.com">gamesurv@neshorntech.com</a>
                    </li>
                    <li>We do not warrant that your use of Game Surv will be uninterrupted and we do not warrant that
                        any information (or messages) transmitted via Game Surv will be transmitted accurately,
                        reliably, in a timely manner or at all. Notwithstanding that we will try to allow uninterrupted
                        access to Game Surv, access to Game Surv may be suspended, restricted or terminated at any time.
                    </li>
                    <li>We do not give any warranty that Game Surv are free from viruses or anything else which may have
                        a harmful effect on any technology.</li>
                    <li>We reserve the right to change, modify, substitute, suspend or remove without notice any
                        information on Game Surv from time to time. Your access to Game Surv may also be occasionally
                        restricted to allow for repairs, maintenance or the introduction of new facilities or services.
                        We will attempt to restore such access as soon as we reasonably can. For the avoidance of doubt,
                        we reserve the right to withdraw any information from Game Surv at any time.</li>
                    <li>We reserve the right to block access to and/or to edit or remove any material which in our
                        reasonable opinion may give rise to a breach of these Terms and Conditions of Use.</li>
                </ol>
            </li>
            <li class="header">
                SUSPENSION AND TERMINATION
                <ol class="inner">
                    <li>If you use (or anyone other than you, with your permission) Game Surv, in contravention of these
                        Terms and Conditions, we may suspend your use of Game Surv.
                    </li>
                    <li>If we suspend Game Surv, we may refuse to restore Game Surv for your use until we receive an
                        assurance from you, in a form we deem acceptable, that there will be no further breach of the
                        provisions of these Terms and Conditions of Use.</li>
                    <li>Game Surv shall fully co-operate with any law enforcement authorities or court order requesting
                        or directing Game Surv or its affiliated companies to disclose the identity or locate anyone in
                        breach of these Terms and Conditions of Use.</li>
                    <li>Without limitation to anything else, we shall be entitled immediately or at any time (in whole
                        or in part) to: (a) suspend Game Surv; (b) suspend your use of Game Surv; and/or (c) suspend the
                        use of Game Surv for persons we believe to be connected (in whatever manner) to you, if:
                        <ol>
                            <li>you commit any breach of these Terms and Conditions of Use;</li>
                            <li>we suspect, on reasonable grounds, that you have, might or will commit a breach of these
                                Terms and Conditions of Use; or;</li>
                            <li>we suspect, on reasonable grounds, that you may have committed or be committing any
                                fraud against us or any person.</li>
                        </ol>
                    </li>
                    <li>Our rights shall not prejudice any other right or remedy we may have in respect of any breach or
                        any rights, obligations or liabilities accrued prior to termination.</li>
                </ol>
            </li>

            <li class="header">
                DISCLAIMER AND EXCLUSION OF LIABILITY
                <ol class="inner">
                    <li>Game Surv and use of all related facilities are provided on an "as is, as available" basis
                        without any warranties whether express or implied.</li>
                    <li>To the fullest extent permitted by applicable law, we disclaim all representations and
                        warranties relating to Game Surv and its contents, including in relation to any inaccuracies or
                        omissions in Game Surv, warranties of merchantability, quality, fitness for a particular
                        purpose, accuracy, availability, non-infringement or implied warranties from course of dealing
                        or usage of trade.</li>
                    <li>We do not warrant that Game Surv will always be accessible, uninterrupted, timely, secure, error
                        free or free from computer virus or other invasive or damaging code or that Game Surv will not
                        be affected by any acts of God or other force majeure events, including inability to obtain or
                        shortage of necessary materials, equipment facilities, power or telecommunications, lack of
                        telecommunications equipment or facilities and failure of information technology or
                        telecommunications equipment or facilities.</li>
                    <li>While we may use reasonable efforts to include accurate and up-to-date information on Game Surv,
                        we make no warranties or representations as to its accuracy, timeliness or completeness.</li>
                    <li>We shall not be liable for any acts or omissions of any third parties and/or yourself, howsoever
                        caused, and for any direct, indirect, incidental, special, consequential or punitive damages,
                        howsoever and/or by whoever caused, resulting from or in connection with Game Surv and the
                        services offered in Game Surv, your access to, use of or inability to use Game Surv or the
                        services offered in Game Surv, reliance on or downloading from Game Surv and/or services, or any
                        delays, inaccuracies in the information or in its transmission including but not limited to
                        damages for loss of business or profits, use, data or other intangible, even if we have been
                        advised of the possibility of such damages.</li>
                    <li>We shall not be liable in contract or otherwise howsoever and whatever the cause thereof, for
                        any indirect, consequential, collateral, special or incidental loss or damage suffered or
                        incurred by you in connection with Game Surv and these Terms and Conditions of Use. For the
                        purposes of these Terms and Conditions of Use, indirect or consequential loss or damage
                        includes, without limitation, loss of revenue, profits, anticipated savings or business, loss of
                        data or goodwill, loss of use or value of any equipment including software, claims of third
                        parties, and all associated and incidental costs and expenses.</li>
                    <li>The above exclusions and limitations apply only to the extent permitted by law. None of your
                        statutory rights as a consumer that cannot be excluded or limited are affected.</li>
                    <li>Notwithstanding our efforts to ensure that our system is secure, you acknowledge that all
                        electronic data transfers are potentially susceptible to interception by others. We cannot, and
                        do not, warrant that data transfers pursuant to Game Surv, or electronic mail transmitted to and
                        from us, will not be monitored or read by others.</li>
                </ol>
            </li>
            <li class="header">
                INDEMNITY
            </li>
            <p>You agree to indemnify and keep us indemnified against any claim, action, suit or proceeding brought or
                threatened to be brought against us which is caused by or arising out of (a) your use of the Services,
                (b) any other party’s use of the Services using your user ID, verification PIN and/or any identifier
                number, and/or (c) your breach of any of these Terms and Conditions of Use, and to pay us damages, costs
                and interest in connection with such claim, action, suit or proceeding.</p>
            <li class="header">
                INTELLECTUAL PROPERTY RIGHTS
                <ol class="inner">
                    <li>All editorial content, information, photographs, illustrations, artwork and other graphic
                        materials, and names, logos and trade marks on Game Surv are protected by copyright laws and/or
                        other laws and/or international treaties, and belong to us and/or our suppliers, as the case may
                        be. These works, logos, graphics, sounds or images may not be copied, reproduced, retransmitted,
                        distributed, disseminated, sold, published, broadcasted or circulated whether in whole or in
                        part, unless expressly permitted by us and/or our suppliers, as the case may be.</li>
                    <li>Nothing contained on Game Surv should be construed as granting by implication, estoppel, or
                        otherwise, any license or right to use any trademark displayed on Game Surv without our written
                        permission. Misuse of any trademarks or any other content displayed on Game Surv is prohibited.
                    </li>
                    <li>We will not hesitate to take legal action against any unauthorised usage of our trade marks,
                        name or symbols to preserve and protect its rights in the matter. All rights not expressly
                        granted herein are reserved. Other company names mentioned herein may also be the trade marks of
                        their respective owners.</li>
                </ol>
            </li>
            <li class="header">
                AMENDMENTS
                <ol class="inner">
                    <li>We may periodically make changes to the contents of Game Surv, including to the descriptions and
                        prices of goods and services advertised, at any time and without notice. You are advised to
                        review this periodically for any changes. Changes are effective when they are posted on this
                        page. We assume no liability or responsibility for any errors or omissions in the content of
                        Game Surv.</li>
                    <li>We reserve the right to amend these Terms and Conditions of Use from time to time without
                        notice. The revised Terms and Conditions of Use will be posted on our website and shall take
                        effect from the date of such posting.
                    </li>
                </ol>
            </li>


            <li class="header">
                PRIVACY POLICY
                <ol class="inner">
                    <li>Access to Game Surv and use of the Services are subject to this Privacy Policy. By accessing
                        Game Surv and by continuing to use the services offered, you are deemed to have accepted this
                        Privacy Policy, and in particular, you are deemed to have consented to our use and disclosure of
                        your personal information in the manner prescribed in this Privacy Policy and for the purposes
                        set out in the Terms and Conditions.</li>
                    <li>We have developed this Privacy Policy in order for you to understand how we collect, use,
                        communicate and disclose and make use of your personal information when you use the services on
                        Game Surv.
                        <ol type="a">
                            <li>We will collect and use personal information solely with the objective of fulfilling
                                those purposes specified by us and for other compatible purposes, unless we obtain the
                                consent of the individual concerned or as required by law.</li>
                            <li>We will collect personal information by lawful and fair means and, where appropriate,
                                with the knowledge or consent of the individual concerned.</li>
                            <li>We will protect personal information by reasonable security safeguards against loss or
                                theft, as well as unauthorized access, disclosure, copying, use or modification. While
                                we strive to use commercially acceptable means to protect your Personal Data, we cannot
                                guarantee its absolute security.</li>
                            <li>We will only keep information for as long as it is needed.</li>
                        </ol>
                    </li>
                    <li>We collect several different types of information from users:
                        <ol type="a">
                            <li>While using our service, we may ask you to provide us with certain personally
                                identifiable information that can be used to contact or identify you (<b>"Personal
                                    Data"</b>). Personal identifiable information may include: e-mail address, names,
                                cookies and usage data.</li>
                            <li>When you access the service with a mobile device, we may collect certain information
                                automatically, including, but not limited to, the type of mobile device you use, your
                                mobile device unique ID, the IP address of your mobile device, your mobile operating
                                system, the type of mobile Internet browser you use, unique device identifiers and other
                                diagnostic data supplied to us.
                            </li>
                            <li>We may use and store information about your location if you give us permission to do so
                                (<b>"Location Data"</b>). We use this data to provide features of our service, to
                                improve and customise our service. You can enable or disable location services when you
                                use our service at any time by way of your device settings. However, if you switch
                                location data off, you may not be able to use some of our services.</li>
                            <li>We use cookies and similar tracking technologies to track the activity on our service
                                and we hold certain information. You can instruct your browser to refuse all cookies or
                                to indicate when a cookie is being sent. However, if you do not accept cookies, you may
                                not be able to use some portions of our service.</li>
                            <li>Game Surv uses the collected data for various purposes:
                                <ol type="none">
                                    <li>To provide and maintain our service</li>
                                    <li>To notify you about changes to our service</li>
                                    <li>To allow you to participate in interactive features when you choose to do so
                                    </li>
                                    <li>To provide customer support</li>
                                    <li>To gather analysis or valuable information so that we can improve our service
                                    </li>
                                    <li>To monitor the usage of our service</li>
                                    <li>To detect, prevent and address technical issues</li>
                                    <li>Transfer of Data</li>
                                </ol>
                            </li>
                            <li>Your information, including Personal Data, may be transferred to and maintained on
                                computers located outside of your state, province, country or other governmental
                                jurisdiction where the data protection laws may differ from those of your jurisdiction.
                            </li>
                            <li>Your data is being kept in the EU where we abide by strict rules and regulations
                                pertaining to data security.</li>
                        </ol>
                        <p>
                            We are committed to conducting our business in accordance with these principles in order to
                            ensure that the confidentiality of personal information is protected and maintained.</p>
                    </li>
                </ol>
            </li>
            <li class="header">
                SERVICE PROVIDERS
            </li>
            <p>We may employ third party companies and individuals to facilitate our Service (<b>"Service
                    Providers"</b>), provide the Service on our behalf, perform Service-related services or assist us in
                analysing how our Service is used. </p>
            <li class="header">
                LINKS TO OTHER SITES
            </li>
            <p>Our Service may contain links to other sites that are not operated by us. If you click a third party
                link, you will be directed to that third party's site. We strongly advise you to review the Privacy
                Policy of every site you visit. We have no control over and assume no responsibility for the content,
                privacy policies or practices of any third party sites or services.</p>
            <li class="header">
                CHILDREN'S PRIVACY
            </li>
            <p>Our Service does not address anyone under the age of 18 (<b>"Children"</b>). We do not knowingly collect
                personally identifiable information from anyone under the age of 18. If you are a parent or guardian and
                you are aware that your Child has provided us with Personal Data, please contact us. If we become aware
                that we have collected Personal Data from children without verification of parental consent, we take
                steps to remove that information from our servers.</p>
        </ol>
    </div>
</div>