import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EmailValidator } from '../../../shared/validators/email.validator';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/shared/models';
import { Observable, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { selectCurrentState } from 'src/app/shared/state/reducers';
import { CLEAR_ERROR, RESET_PASSWORD } from 'src/app/shared/state/actions/main-actions.actions';

@Component({
    selector: 'app-gamesurv-reset-password',
    templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

    currentState$: Observable<AppState> = this.store.pipe(delay(0), select(selectCurrentState));
    private stateSubscription: Subscription;

    public resetForm;

    busyResetting = false;

    error = '';
    showError = false;
    showSuccess = false;

    constructor(
        public formBuilder: FormBuilder,
        private store: Store<AppState>
    ) {
        this.busyResetting = false;

        this.resetForm = formBuilder.group({
            email: ['', Validators.compose([Validators.required, EmailValidator.isValid])]
        });
    }

    ngOnInit() {
        this.stateSubscription = this.currentState$.subscribe((appState: AppState) => {
            if (appState && appState.error) {
                this.busyResetting = false;
                this.showError = true;
                this.showSuccess = false;
                this.error = appState.error;
            } else if (appState && appState.reset) {
                this.busyResetting = false;
                this.showError = false;
                this.showSuccess = true;
            }
        });
    }

    ngOnDestroy() {
        this.store.dispatch(new CLEAR_ERROR());
        this.stateSubscription.unsubscribe();
    }

    resetPassword(): void {
        this.showError = false;
        this.busyResetting = true;
        if (!this.resetForm.valid) {
        } else {
            this.store.dispatch(new RESET_PASSWORD({ email: this.resetForm.controls.email.value }));
        }
    }

}
