import { Action } from '@ngrx/store';
import { FarmSection, UserFarm, Count, MethodOfPayment, User, MapLocation, LinkedUser } from '../../models';
import { BillingScale } from '../../models/billing_scale.model';
import { UserLinkingDisplay } from '../../models/display/user-linking-display.model';
import { RoleType } from '../../models/enums/RoleType';

export enum FarmActionTypes {
    LOAD_ALL_FARMS = '[Link Farm Page] Load All Farms',
    LOAD_ALL_FARMS_SUCCESS = '[Link Farm Page] Load All Farms Success',
    LOAD_ALL_FARMS_FAILED = '[Link Farm Page] Load All Farms Error',

    LOAD_USER_FARMS = '[Farms Page] Load User Farms',
    LOAD_USER_FARMS_SUCCESS = '[Farms Page] Load User Farms Success',
    LOAD_USER_FARMS_FAILED = '[Farms Page] Load User Farms Error',

    LOAD_PRICING_SCALES = '[Home Page] Load Pricing Scales',
    LOAD_PRICING_SCALES_SUCCESS = '[Home Page] Load Pricing Scales Success',
    LOAD_PRICING_SCALES_FAILED = '[Home Page] Load Pricing Scales Error',

    LOAD_USER_COUNTS = '[Farms Page] Load User Counts',
    LOAD_USER_COUNTS_SUCCESS = '[Farms Page] Load User Counts Success',
    LOAD_USER_COUNTS_FAILED = '[Farms Page] Load User Counts Error',

    SELECT_FARM = '[Manage Farms Page] Select Farm To Link',
    SELECT_USER_FARM = '[Manage Farms Page] Select Farm To Manage',
    SELECT_FARM_SECTION = '[Manage Farms Page] Select Farm Section To Manage',
    SELECT_USER_FARM_ROLE = '[Manage Farms Page] Select User Farm Role',

    CREATE_FARM = '[New Farm Page] Create New Farm',
    CREATE_NEW_FARM_SUCCESS = '[New Farm Page] Create New Farm Success',
    CREATE_NEW_FARM_FAILED = '[New Farm Page] Create New Farm Error',
    CREATE_NEW_FARM_NAME_CHANGE = '[New Farm Page] Create New Farm Name Change',
    CREATE_NEW_FARM_EMAIL_CHANGE = '[New Farm Page] Create New Farm Email Change',
    CREATE_NEW_FARM_SPECIE_CHANGE = '[New Farm Page] Create New Farm Specie Change',
    CREATE_NEW_FARM_LAYOUT_CHANGE = '[New Farm Page] Create Farm Layout Change',
    CREATE_NEW_FARM_SET_VALIDITY = '[New Farm Page] Create New Farm Set Validity',

    UPDATE_USER_ROLE = '[Manage Users] Update User Role',
    UPDATE_USER_ROLE_SUCCESS = '[Manage Users] Update User Role Success',
    UPDATE_USER_ROLE_FAILED = '[Manage Users] Update User Role Error',

    REMOVE_USER_LINK = '[Manage Users] Remove User Link',
    REMOVE_USER_LINK_SUCCESS = '[Manage Users] Remove User Link Success',
    REMOVE_USER_LINK_FAILED = '[Manage Users] Remove User Link Error',

    ADD_USER_TO_FARM = '[Add Users] Add User To Farm',
    ADD_USER_TO_FARM_SUCCESS = '[Add Users] Add User To Farm Success',
    ADD_USER_TO_FARM_FAILED = '[Add Users] Add User To Farm Error',

    ADD_USER_TO_FARMS = '[Add Users] Add User To Farms',
    ADD_USER_TO_FARMS_SUCCESS = '[Add Users] Add User To Farms Success',
    ADD_USER_TO_FARMS_FAILED = '[Add Users] Add User To Farms Error',

    REMOVE_USER_FROM_FARM = '[Remove User] Remove User From Farm',
    REMOVE_USER_FROM_FARM_SUCCESS = '[Remove User] Remove User From Farm Success',
    REMOVE_USER_FROM_FARM_FAILED = '[Remove User] Remove User From Failed',

    INVITE_USER_TO_FARMS = '[Add Users] Invite User To Farms',
    INVITE_USER_TO_FARMS_SUCCESS = '[Add Users] Invite User To Farms Success',
    INVITE_USER_TO_FARMS_FAILED = '[Add Users] Invite User To Farms Error',

    UPDATE_FARM = '[Update Farm] Update Farm',
    UPDATE_FARM_SUCCESS = '[Update Farm] Update Farm Success',
    UPDATE_FARM_FAILED = '[Update Farm] Update Farm Error',

    UPDATE_FARM_LAYOUT = '[Update Farm] Update Farm Layout',
    UPDATE_FARM_LAYOUT_SUCCESS = '[Update Farm] Update Farm Layout Success',
    UPDATE_FARM_LAYOUT_FAILED = '[Update Farm] Update Farm Layout Error',

    ADD_USER_LINK = '[Manage Users] Add User Link',
    ADD_USER_LINK_SUCCESS = '[Manage Users] Add User Link Success',
    ADD_USER_LINK_FAILED = '[Manage Users] Add User Link Error',

    INVITE_USER_TO_FARM = '[Add Users] Invite User To Farm',
    INVITE_USER_TO_FARM_SUCCESS = '[Add Users] Invite User To Farm Success',
    INVITE_USER_TO_FARM_FAILED = '[Add Users] Invite User To Farm Error',

    ADD_CARD_TO_FARM = '[Farm Page] Add Card To Farm',
    ADD_CARD_TO_FARM_SUCCESS = '[Farm Page] Add Card To Farm Success',
    ADD_CARD_TO_FARM_FAILED = '[Farm Page] Add Card To Farm Error',

    REMOVE_CARD_FROM_FARM = '[Farm Page] Remove Card From Farm',
    REMOVE_CARD_FROM_FARM_SUCCESS = '[Farm Page] Remove Card From Farm Success',
    REMOVE_CARD_FROM_FARM_FAILED = '[Farm Page] Remove Card From Farm Error',

    UPDATE_FARM_ANIMALS = '[Farm Page] Update Farm Animals',
    UPDATE_FARM_ANIMALS_SUCCESS = '[Farm Page] Update Farm Animals Success',
    UPDATE_FARM_ANIMALS_FAILED = '[Farm Page] Update Farm Animals Error'

}

export class FarmAction implements Action {
    type: string;
    payload: {
        id: string;
        farms: UserFarm[];
        farm: UserFarm;
        section: FarmSection;
        farm_id: string;
        section_id: string,
        error: string;
        name: string;
        species_selection: number[];
        layout: FarmSection;
        is_valid: boolean;
    };
}

export class LOAD_ALL_FARMS implements Action {
    readonly type = FarmActionTypes.LOAD_ALL_FARMS;
}

export class LOAD_ALL_FARMS_SUCCESS implements Action {
    readonly type = FarmActionTypes.LOAD_ALL_FARMS_SUCCESS;

    constructor(public payload: { farms: UserFarm[] }) { }
}

export class LOAD_ALL_FARMS_FAILED implements Action {
    readonly type = FarmActionTypes.LOAD_ALL_FARMS_FAILED;

    constructor(readonly payload: { error: string }) {
    }
}

export class LOAD_FARMS implements Action {
    readonly type = FarmActionTypes.LOAD_USER_FARMS;

    constructor(public payload: { id: string }) { }
}

export class LOAD_FARMS_SUCCESS implements Action {
    readonly type = FarmActionTypes.LOAD_USER_FARMS_SUCCESS;

    constructor(public payload: { farms: UserFarm[] }) { }
}

export class LOAD_FARMS_FAILED implements Action {
    readonly type = FarmActionTypes.LOAD_USER_FARMS_FAILED;

    constructor(readonly payload: { error: string }) {
    }
}

export class LOAD_PRICING_SCALES implements Action {
    readonly type = FarmActionTypes.LOAD_PRICING_SCALES;
}

export class LOAD_PRICING_SCALES_SUCCESS implements Action {
    readonly type = FarmActionTypes.LOAD_PRICING_SCALES_SUCCESS;

    constructor(public payload: { scales: BillingScale[] }) { }
}

export class LOAD_PRICING_SCALES_FAILED implements Action {
    readonly type = FarmActionTypes.LOAD_PRICING_SCALES_FAILED;

    constructor(readonly payload: { error: string }) {
    }
}

export class LOAD_COUNTS implements Action {
    readonly type = FarmActionTypes.LOAD_USER_COUNTS;

    constructor(public payload: { farms: UserFarm[] }) { }
}

export class LOAD_COUNTS_SUCCESS implements Action {
    readonly type = FarmActionTypes.LOAD_USER_COUNTS_SUCCESS;

    constructor(public payload: { counts: Count[] }) { }
}

export class LOAD_COUNTS_FAILED implements Action {
    readonly type = FarmActionTypes.LOAD_USER_COUNTS_FAILED;

    constructor(readonly payload: { error: string }) {
    }
}

export class CREATE_FARM implements Action {
    readonly type = FarmActionTypes.CREATE_FARM;

    constructor(public payload: { farm: UserFarm; }) { }
}

export class CREATE_FARM_SUCCESS implements Action {
    readonly type = FarmActionTypes.CREATE_NEW_FARM_SUCCESS;

    constructor(public payload: { farm: UserFarm; }) { }
}

export class CREATE_FARM_FAILED implements Action {
    readonly type = FarmActionTypes.CREATE_NEW_FARM_FAILED;

    constructor(public payload: { error: string; }) { }
}


export class SELECT_FARM implements Action {
    readonly type = FarmActionTypes.SELECT_FARM;

    constructor(public payload: { farm_id: string; }) { }
}

export class SELECT_USER_FARM implements Action {
    readonly type = FarmActionTypes.SELECT_USER_FARM;

    constructor(public payload: { farm_id: string; }) { }
}

export class SELECT_FARM_SECTION implements Action {
    readonly type = FarmActionTypes.SELECT_FARM_SECTION;

    constructor(public payload: { section_id: string; }) { }
}

export class SELECT_USER_FARM_ROLE implements Action {
    readonly type = FarmActionTypes.SELECT_USER_FARM_ROLE;
}

export class UPDATE_USER_ROLE implements Action {
    readonly type = FarmActionTypes.UPDATE_USER_ROLE;

    constructor(public payload: { farm: UserFarm; }) { }
}

export class UPDATE_USER_ROLE_SUCCESS implements Action {
    readonly type = FarmActionTypes.UPDATE_USER_ROLE_SUCCESS;

    constructor(public payload: { farm: UserFarm; }) { }
}

export class UPDATE_USER_ROLE_FAILED implements Action {
    readonly type = FarmActionTypes.UPDATE_USER_ROLE_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class REMOVE_USER_LINK implements Action {
    readonly type = FarmActionTypes.REMOVE_USER_LINK;

    constructor(public payload: { id: string; }) { }
}

export class REMOVE_USER_LINK_SUCCESS implements Action {
    readonly type = FarmActionTypes.REMOVE_USER_LINK_SUCCESS;

    constructor(public payload: { farm: UserFarm; }) { }
}

export class REMOVE_USER_LINK_FAILED implements Action {
    readonly type = FarmActionTypes.REMOVE_USER_LINK_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class ADD_USER_TO_FARM implements Action {
    readonly type = FarmActionTypes.ADD_USER_TO_FARM;

    constructor(public payload: { user: UserLinkingDisplay; }) { }
}

export class ADD_USER_TO_FARM_SUCCESS implements Action {
    readonly type = FarmActionTypes.ADD_USER_TO_FARM_SUCCESS;

    constructor(public payload: { farm: UserFarm; }) { }
}

export class ADD_USER_TO_FARM_FAILED implements Action {
    readonly type = FarmActionTypes.ADD_USER_TO_FARM_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class ADD_USER_TO_FARMS implements Action {
    readonly type = FarmActionTypes.ADD_USER_TO_FARMS;

    constructor(public payload: { user: User, role: RoleType, farms: string[] }) { }
}

export class ADD_USER_TO_FARMS_SUCCESS implements Action {
    readonly type = FarmActionTypes.ADD_USER_TO_FARMS_SUCCESS;

    constructor(public payload: { user: User, role: RoleType, farms: string[] }) { }
}

export class ADD_USER_TO_FARMS_FAILED implements Action {
    readonly type = FarmActionTypes.ADD_USER_TO_FARMS_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class REMOVE_USER_FROM_FARM implements Action {
    readonly type = FarmActionTypes.REMOVE_USER_FROM_FARM;

    constructor(public payload: { user: LinkedUser, farm: string }) { }
}

export class REMOVE_USER_FROM_FARM_SUCCESS implements Action {
    readonly type = FarmActionTypes.REMOVE_USER_FROM_FARM_SUCCESS;

    constructor(public payload: { users: LinkedUser[], farm: string }) { }
}

export class REMOVE_USER_FROM_FARM_FAILED implements Action {
    readonly type = FarmActionTypes.REMOVE_USER_FROM_FARM_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class INVITE_USER_TO_FARMS implements Action {
    readonly type = FarmActionTypes.INVITE_USER_TO_FARMS;

    constructor(public payload: { email: string, role: RoleType, farms: string[] }) { }
}

export class INVITE_USER_TO_FARMS_SUCCESS implements Action {
    readonly type = FarmActionTypes.INVITE_USER_TO_FARMS_SUCCESS;

    constructor(public payload: { email: string, role: RoleType, farms: string[] }) { }
}

export class INVITE_USER_TO_FARMS_FAILED implements Action {
    readonly type = FarmActionTypes.INVITE_USER_TO_FARMS_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class UPDATE_FARM implements Action {
    readonly type = FarmActionTypes.UPDATE_FARM;

    constructor(public payload: { farm: UserFarm }) { }
}

export class UPDATE_FARM_SUCCESS implements Action {
    readonly type = FarmActionTypes.UPDATE_FARM_SUCCESS;

    constructor(public payload: { farm: UserFarm }) { }
}

export class UPDATE_FARM_FAILED implements Action {
    readonly type = FarmActionTypes.UPDATE_FARM_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class UPDATE_FARM_LAYOUT implements Action {
    readonly type = FarmActionTypes.UPDATE_FARM_LAYOUT;

    constructor(public payload: { farmId: string, layout: MapLocation[] }) { }
}

export class UPDATE_FARM_LAYOUT_SUCCESS implements Action {
    readonly type = FarmActionTypes.UPDATE_FARM_LAYOUT_SUCCESS;

    constructor(public payload: { farmId: string, layout: MapLocation[] }) { }
}

export class UPDATE_FARM_LAYOUT_FAILED implements Action {
    readonly type = FarmActionTypes.UPDATE_FARM_LAYOUT_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class ADD_USER_LINK implements Action {
    readonly type = FarmActionTypes.ADD_USER_LINK;

    constructor(public payload: { id: string; }) { }
}

export class ADD_USER_LINK_SUCCESS implements Action {
    readonly type = FarmActionTypes.ADD_USER_LINK_SUCCESS;

    constructor(public payload: { farm: UserFarm; }) { }
}

export class ADD_USER_LINK_FAILED implements Action {
    readonly type = FarmActionTypes.ADD_USER_LINK_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class INVITE_USER_TO_FARM implements Action {
    readonly type = FarmActionTypes.INVITE_USER_TO_FARM;

    constructor(public payload: { email: string; }) { }
}

export class INVITE_USER_TO_FARM_SUCCESS implements Action {
    readonly type = FarmActionTypes.INVITE_USER_TO_FARM_SUCCESS;

    constructor(public payload: { farm: UserFarm; }) { }
}

export class INVITE_USER_TO_FARM_FAILED implements Action {
    readonly type = FarmActionTypes.INVITE_USER_TO_FARM_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class ADD_CARD_TO_FARM implements Action {
    readonly type = FarmActionTypes.ADD_CARD_TO_FARM;

    constructor(public payload: { farmId: string, paymentMethod: MethodOfPayment; }) { }
}

export class ADD_CARD_TO_FARM_SUCCESS implements Action {
    readonly type = FarmActionTypes.ADD_CARD_TO_FARM_SUCCESS;

    constructor(public payload: { farmId: string, paymentMethod: MethodOfPayment; }) { }
}

export class ADD_CARD_TO_FARM_FAILED implements Action {
    readonly type = FarmActionTypes.ADD_CARD_TO_FARM_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class REMOVE_CARD_FROM_FARM implements Action {
    readonly type = FarmActionTypes.REMOVE_CARD_FROM_FARM;

    constructor(public payload: { customerId: string, paymentMethodId: string; }) { }
}

export class REMOVE_CARD_FROM_FARM_SUCCESS implements Action {
    readonly type = FarmActionTypes.REMOVE_CARD_FROM_FARM_SUCCESS;

    constructor(public payload: { result: boolean }) { }
}

export class REMOVE_CARD_FROM_FARM_FAILED implements Action {
    readonly type = FarmActionTypes.REMOVE_CARD_FROM_FARM_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class UPDATE_FARM_ANIMALS implements Action {
    readonly type = FarmActionTypes.UPDATE_FARM_ANIMALS;

    constructor(public payload: { farmId: string, species: number[]; }) { }
}

export class UPDATE_FARM_ANIMALS_SUCCESS implements Action {
    readonly type = FarmActionTypes.UPDATE_FARM_ANIMALS_SUCCESS;

    constructor(public payload: { farmId: string, species: number[];  }) { }
}

export class UPDATE_FARM_ANIMALS_FAILED implements Action {
    readonly type = FarmActionTypes.UPDATE_FARM_ANIMALS_FAILED;

    constructor(public payload: { error: string; }) { }
}

export type FarmActionsUnion = CREATE_FARM | CREATE_FARM_SUCCESS | CREATE_FARM_FAILED
| LOAD_FARMS | LOAD_FARMS_SUCCESS | LOAD_FARMS_FAILED
| LOAD_PRICING_SCALES | LOAD_PRICING_SCALES_SUCCESS | LOAD_PRICING_SCALES_FAILED
| SELECT_FARM | SELECT_USER_FARM | SELECT_FARM_SECTION | SELECT_USER_FARM_ROLE
| UPDATE_USER_ROLE | UPDATE_USER_ROLE_SUCCESS | UPDATE_USER_ROLE_FAILED
| REMOVE_USER_LINK | REMOVE_USER_LINK_SUCCESS | REMOVE_USER_LINK_FAILED
| ADD_USER_TO_FARM | ADD_USER_TO_FARM_SUCCESS | ADD_USER_TO_FARM_FAILED
| ADD_USER_TO_FARMS | ADD_USER_TO_FARMS_SUCCESS | ADD_USER_TO_FARMS_FAILED
| REMOVE_USER_FROM_FARM | REMOVE_USER_FROM_FARM_SUCCESS | REMOVE_USER_FROM_FARM_FAILED
| UPDATE_FARM | UPDATE_FARM_SUCCESS | UPDATE_FARM_FAILED
| UPDATE_FARM_LAYOUT | UPDATE_FARM_LAYOUT_SUCCESS | UPDATE_FARM_LAYOUT_FAILED
| ADD_USER_LINK | ADD_USER_LINK_SUCCESS | ADD_USER_LINK_FAILED
| INVITE_USER_TO_FARM | INVITE_USER_TO_FARM_SUCCESS | INVITE_USER_TO_FARM_FAILED
| ADD_CARD_TO_FARM | ADD_CARD_TO_FARM_SUCCESS | ADD_CARD_TO_FARM_FAILED
| REMOVE_CARD_FROM_FARM | REMOVE_CARD_FROM_FARM_SUCCESS | REMOVE_CARD_FROM_FARM_FAILED
| UPDATE_FARM_ANIMALS | UPDATE_FARM_ANIMALS_SUCCESS | UPDATE_FARM_ANIMALS_FAILED;
