
import { SidebarProvider } from './sidebar.provider';
import { FirebaseProvider } from './firebase.provider';
import { ParseServerProvider } from './parse-server.provider';


export const sharedProviders = [
    SidebarProvider,
    FirebaseProvider,
    ParseServerProvider
];
