<div class="container">
    <div class="auth-card">
        <h1 class="text-uppercase">Sign up to</h1>
        <h1 class="text-uppercase pb-20"><span>GameSurv</span></h1>
        <form class="auth-form" [formGroup]="registerForm" (ngSubmit)="registerUser()" novalidate>
            <div class="input-group mb-3">
                <input type="text" formControlName="name" class="form-control" placeholder="Name" aria-label="Name"
                    aria-describedby="Name">
            </div>
            <small *ngIf="!registerForm.controls.name.valid && registerForm.controls.name.dirty" id="nameError"
                class="text-danger form-text">Please enter your name.</small>

            <div class="input-group mb-3">
                <input type="text" formControlName="surname" class="form-control" placeholder="Surname"
                    aria-label="Surname" aria-describedby="Surname">
            </div>
            <small *ngIf="!registerForm.controls.surname.valid && registerForm.controls.surname.dirty" id="surnameError"
                class="text-danger form-text">Please enter your surname.</small>
            <div class="input-group mb-3">
                <input type="email" formControlName="email" class="form-control" placeholder="Email" aria-label="Email"
                    aria-describedby="Email">
            </div>
            <small *ngIf="!registerForm.controls.email.valid && registerForm.controls.email.dirty" id="emailError"
                class="text-danger form-text">Please enter a valid email.</small>
            <div class="input-group mb-3">
                <input type="password" formControlName="password" class="form-control" placeholder="Password"
                    aria-label="Password" aria-describedby="Password">
            </div>
            <small *ngIf="f.password.errors?.minlength && f.password.dirty" class="text-danger form-text">Password
                is not yet long enough.</small>
            <small *ngIf="f.password.errors?.required && f.password.dirty" class="text-danger form-text">Password is
                required.</small>
            <div class="input-group mb-3">
                <input type="password" formControlName="confirm_password" class="form-control"
                    placeholder="Confirm password" aria-label="Confirm Password" aria-describedby="Confirm Password">
            </div>
            <small *ngIf="f.confirm_password.errors?.required && f.confirm_password.dirty"
                class="text-danger form-text">Confirm Password is required.</small>
            <small *ngIf="f.confirm_password.errors?.equalValueValidator && f.confirm_password.dirty"
                class="text-danger form-text">Password mismatch.</small>
        </form>
        <p class="alt-text">By signing up I agree to the <a [routerLink]="['/privacy-policy']">Privacy Policy</a>.</p>

        <p class="alt-text mb-10">Have an account? <a [routerLink]="['/sign-in']">Sign in</a></p>
        <button type="button" class="btn btn-secondary mb-20" [routerLink]="['/']">
            Cancel
        </button>
        <button type="button" class="btn btn-primary mb-10" (click)="registerUser()"
            [disabled]="busyRegistering || registerForm.invalid">
            Create free account
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                *ngIf="busyRegistering"></span>
        </button>
        <p class="error-message" *ngIf="showError">{{error}}</p>
    </div>
</div>