// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB5S8h35Gb9_57QV9GtQ3upsbRGlTa2vj8",
    authDomain: "game-surv-test.firebaseapp.com",
    databaseURL: "https://game-surv-test.firebaseio.com",
    projectId: "game-surv-test",
    storageBucket: "game-surv-test.appspot.com",
    messagingSenderId: "304211550211",
    appId: "1:304211550211:web:65668b5c1cdf357c39ee36",
    measurementId: "G-J744013DWE"
  },
  parse: {
    app_id: "AbLPlrI7iMTH0G6g4EWa4pVYVlzzIeuakO1nwPnU", 
    javascript_key: "1qndh0FRxW2O3tg3l4vYuwpSacbz0ZmziRKC5fNA"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
