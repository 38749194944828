import { Action } from '@ngrx/store';
import { User, Credentials, SignupUserInfo, UserFarm } from '../../models';

export enum UserActionTypes {
    LOGIN = '[Login Page] Login User',
    NEW_LOGIN = '[Login Page] Login User New',
    LOGIN_SUCCESS = '[Login Page] Login User Success',
    LOGIN_SUCCESS_AND_MOVE = '[Login Page] Login User Success And Move',
    LOGIN_FAILED = '[Login Page] Login User Failed',
    REGISTER = '[Register Page] Register User',
    REGISTER_SUCCESS = '[Register Page] Register User Success',
    REGISTER_FAILED = '[Register Page] Register User Failed',
    RESET_PASSWORD = '[Reset Password Page] Reset Password',
    RESET_PASSWORD_SUCCESS = '[Reset Password Page] Reset Password Success',
    RESET_PASSWORD_FAILED = '[Reset Password Page] Reset Password Failed',
    LOGOUT = '[Logout Page] Logout User',
    LOGOUT_SUCCESS = '[Logout Page] Logout User Success',
    LOGOUT_FAILED = '[Logout Page] Logout User Failed',
    LOAD_USER_DETAILS = '[Home Page] Load User Details',
    LOAD_USER_DETAILS_SUCCESS = '[Home Page] Load User Details Success',
    LOAD_USER_DETAILS_FAILED = '[Home Page] Load User Details Failed',
    LOAD_ALL_USERS = '[Home Page] Load All Users',
    LOAD_ALL_USERS_SUCCESS = '[Home Page] Load All Users Success',
    LOAD_ALL_USERS_FAILED = '[Home Page] Load All Users Failed'
}

export class UserAction implements Action {
    type: string;
    payload: {
        signupUserInfo: SignupUserInfo;
        userData: User;
        creds: Credentials;
        farms: UserFarm[];
        error: string;
    };
}

export class LOGIN_USER implements Action {
    readonly type = UserActionTypes.LOGIN;

    constructor(public payload: { creds: Credentials }) {
    }
}

export class NEW_LOGIN_USER implements Action {
    readonly type = UserActionTypes.NEW_LOGIN;

    constructor(public payload: { creds: Credentials }) {
    }
}

export class LOGIN_USER_SUCCESS implements Action {
    readonly type = UserActionTypes.LOGIN_SUCCESS;
}

export class LOGIN_USER_SUCCESS_AND_MOVE implements Action {
    readonly type = UserActionTypes.LOGIN_SUCCESS_AND_MOVE;
}

export class LOGIN_USER_FAILED implements Action {
    readonly type = UserActionTypes.LOGIN_FAILED;

    constructor(readonly payload: { error: string }) {
    }
}

export class REGISTER_USER implements Action {
    readonly type = UserActionTypes.REGISTER;

    constructor(public payload: { userInfo: SignupUserInfo }) {
    }
}

export class REGISTER_USER_SUCCESS implements Action {
    readonly type = UserActionTypes.REGISTER_SUCCESS;
}

export class REGISTER_USER_FAILED implements Action {
    readonly type = UserActionTypes.REGISTER_FAILED;

    constructor(readonly payload: { error: string }) {
    }
}

export class RESET_PASSWORD implements Action {
    readonly type = UserActionTypes.RESET_PASSWORD;

    constructor(public payload: { email: string }) {
    }
}

export class RESET_PASSWORD_SUCCESS implements Action {
    readonly type = UserActionTypes.RESET_PASSWORD_SUCCESS;

    constructor(public payload: { result: boolean }) {
    }
}

export class RESET_PASSWORD_FAILED implements Action {
    readonly type = UserActionTypes.RESET_PASSWORD_FAILED;

    constructor(readonly payload: { error: string }) {
    }
}

export class LOGOUT_USER implements Action {
    readonly type = UserActionTypes.LOGOUT;
}

export class LOGOUT_USER_SUCCESS implements Action {
    readonly type = UserActionTypes.LOGOUT_SUCCESS;
}

export class LOGOUT_USER_FAILED implements Action {
    readonly type = UserActionTypes.LOGOUT_FAILED;

    constructor(readonly payload: { error: string }) {
    }
}

export class LOAD_USER implements Action {
    readonly type = UserActionTypes.LOAD_USER_DETAILS;
}

export class LOAD_USER_SUCCESS implements Action {
    readonly type = UserActionTypes.LOAD_USER_DETAILS_SUCCESS;

    constructor(public payload: { userData: User }) {
    }
}

export class LOAD_USER_FAILED implements Action {
    readonly type = UserActionTypes.LOAD_USER_DETAILS_FAILED;

    constructor(readonly payload: { error: string }) {
    }
}

export class LOAD_ALL_USERS implements Action {
    readonly type = UserActionTypes.LOAD_ALL_USERS;
}

export class LOAD_ALL_USERS_SUCCESS implements Action {
    readonly type = UserActionTypes.LOAD_ALL_USERS_SUCCESS;

    constructor(public payload: { users: User[] }) {
    }
}

export class LOAD_ALL_USERS_FAILED implements Action {
    readonly type = UserActionTypes.LOAD_ALL_USERS_FAILED;

    constructor(readonly payload: { error: string }) {
    }
}

export type UserActionsUnion = LOGIN_USER | NEW_LOGIN_USER | LOGIN_USER_SUCCESS | LOGIN_USER_SUCCESS_AND_MOVE | LOGIN_USER_FAILED
    | REGISTER_USER | REGISTER_USER_SUCCESS | REGISTER_USER_FAILED
    | RESET_PASSWORD | RESET_PASSWORD_SUCCESS | RESET_PASSWORD_FAILED
    | LOGOUT_USER | LOGOUT_USER_SUCCESS
    | LOGOUT_USER_FAILED | LOAD_USER | LOAD_USER_SUCCESS | LOAD_USER_FAILED
    | LOAD_ALL_USERS | LOAD_ALL_USERS_SUCCESS | LOAD_ALL_USERS_FAILED;
