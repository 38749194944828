
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AnimalsActionTypes, LOAD_ANIMALS, LOAD_ANIMALS_SUCCESS, LOAD_ANIMALS_FAILED } from '../actions/animals.actions';
import { of, from } from 'rxjs';
import { Animal } from '../../models';
import { ParseServerProvider } from '../../providers/parse-server.provider';

@Injectable()
export class AnimalsEffects {

    @Effect()
    loadAnimals$ = this.actions$
        .pipe(
            ofType<LOAD_ANIMALS>(AnimalsActionTypes.LOAD_ANIMALS),
            switchMap(() => {
                return from(this.parseServerProvider.getAllAnimals()).pipe(
                    map((animalsResult: Animal[]) => {
                        animalsResult.sort((a,b) => a.name.localeCompare(b.name));
                        return new LOAD_ANIMALS_SUCCESS({ animals: animalsResult })
                    }),
                    catchError((errorMessage) => of(new LOAD_ANIMALS_FAILED({ error: errorMessage })))
                )
            })
        );

    constructor(private actions$: Actions, private parseServerProvider: ParseServerProvider) { }

}
