import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NoContentComponent } from './pages/no-content/no-content.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';


// Auth
import { LoginComponent } from './pages/auth/login/login.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    loadChildren : () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule)
  },
  {
    path: 'sign-in',
    component: LoginComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'sign-up',
    component: RegisterComponent
  },
  {
    path: 'gamesurv',
    loadChildren : () => import('./pages/gamesurv/gamesurv.module').then(m => m.GameSurvModule)
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: '404',
    pathMatch: 'full',
    component: NoContentComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
