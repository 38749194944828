<div class="container">
    <div class="auth-card">
        <h1 class="text-uppercase pb-20">Reset Password</h1>

        <form class="auth-form" [formGroup]="resetForm" (ngSubmit)="resetPassword()" novalidate>
            <div class="input-group mb-3">
                <input type="email" formControlName="email" class="form-control" placeholder="Email" aria-label="Email"
                    aria-describedby="Email">
            </div>
            <small *ngIf="!resetForm.controls.email.valid && resetForm.controls.email.dirty" id="emailError"
                class="text-danger form-text">Please enter a valid email.</small>
        </form>
        <button type="button" class="btn btn-secondary mb-20" [routerLink]="['/sign-in']">
            Cancel
        </button>
        <button type="button" class="btn btn-primary mb-10" (click)="resetPassword()"
            [disabled]="busyResetting || resetForm.invalid">
            Reset password
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                *ngIf="busyResetting"></span>
        </button>
        <p class="error-message" *ngIf="showError">{{error}}</p>
        <p class="reset-message" *ngIf="showSuccess">Password reset email has been sent.</p>
    </div>
</div>