import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, CountDetail, SignupUserInfo, Credentials, UserFarm, Animal, User, LinkedUser, MapLocation, Count, Payment } from '../models';
import { APP_STAT_USERS, APP_STAT_OTHER, LOAD_FARMS, LOAD_ALL_USERS, LOAD_ANIMALS, LOAD_USER_SUCCESS } from '../state/actions/main-actions.actions';
import { Observable, Subject } from 'rxjs';
import { BillingScale } from '../models/billing_scale.model';
import { RoleType } from '../models/enums/RoleType';
import { LinkedUserCount } from '../models/linked-user-count.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class ParseServerProvider {

    private user: Subject<Parse.User> = new Subject<Parse.User>();
    public loggedIn = new Subject<boolean>();

    constructor(private router: Router, private store: Store<AppState>) {
        // Initialize your Parse App Keys
        Parse.initialize(environment.parse.app_id, environment.parse.javascript_key);
        Parse.serverURL = 'https://parseapi.back4app.com/';
        this.loadUserRegistered().then((users) => this.store.dispatch(new APP_STAT_USERS({ users: users })));
        this.loadAppStats().then((stats) => this.store.dispatch(new APP_STAT_OTHER({ stats: stats })));


        this.user.subscribe(
            (user) => {
                if (user) {
                    const userVal: User = {
                        id: Parse.User.current().id,
                        name: Parse.User.current().get('name'),
                        surname: Parse.User.current().get('surname'),
                        email: Parse.User.current().get('email'),
                        device_id: ''
                    };
                    setTimeout(() => {
                        this.store.dispatch(new LOAD_USER_SUCCESS({ userData: userVal }));
                        this.store.dispatch(new LOAD_FARMS({ id: user.id }));
                        this.store.dispatch(new LOAD_ALL_USERS());
                        this.store.dispatch(new LOAD_ANIMALS());
                    }, 1);
                    localStorage.setItem('user', JSON.stringify(userVal));
                    this.loggedIn.next(true);
                    JSON.parse(localStorage.getItem('user'));

                }
                else {
                    localStorage.setItem('user', null);
                    this.loggedIn.next(false);
                    JSON.parse(localStorage.getItem('user'));
                }
            }
        );
        this.user.next(Parse.User.current());
    }

    async checkUserMovedOver(email: String): Promise<boolean> {
        const userObject = Parse.Object.extend('User');
        const query = new Parse.Query(userObject);
        query.equalTo('email', email);
        const user = await query.find();
        if (user !== null) {
            return true;
        }
        return false;
    }

    async loadAppStats() {

        let appStats: { farms: number, counts: number, hectare: number } = { farms: 0, counts: 0, hectare: 0 };
        let allCounts: { id: string, detail: CountDetail[] }[] = [];

        const farmsObject = Parse.Object.extend('Farms');
        const query = new Parse.Query(farmsObject);
        return query.find().then((farms) => {

            let hectareTotal = 0;
            farms.forEach((farm) => {
                hectareTotal = hectareTotal + farm.get('size');
            });
            appStats.farms = farms.length;
            appStats.hectare = hectareTotal;
            return farms;
        }).then((farms) => {
            let countPromises = [];

            farms.forEach((farm) => {
                const countsObject = Parse.Object.extend('Counts');
                const query = new Parse.Query(countsObject);
                let pointer = {
                    '__type': 'Pointer',
                    'className': 'Farms',
                    'objectId': farm.id
                };
                query.equalTo('farm', pointer);
                const countPromise = query.find().then((counts) => {

                    counts.forEach(async (count) => {
                        let details: Array<CountDetail> = new Array<CountDetail>();

                        const countVal: { id: string, detail: CountDetail[] } = {
                            id: count['objectId'],
                            detail: details
                        };
                        allCounts.push(countVal);
                    });
                });

                countPromises.push(countPromise);
            });

            return Promise.all([...countPromises]).then((val) => {
                appStats.counts = allCounts.length;
                return appStats;
            });
        });
    }

    async loadUserRegistered(): Promise<number> {
        const usersObject = Parse.Object.extend('User');
        const query = new Parse.Query(usersObject);
        return query.find().then((results) => results.length);
    }

    isLoggedIn() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user == null) {
            return false;
        } else {
            return true;
        }
    }

    async authenticate(creds: Credentials): Promise<any> {
        return Parse.User.logIn(creds.username, creds.password).then((user) => {
            this.user.next(Parse.User.current());
            return user;
        }).catch((error: any) => {
            return { error };
        });
    }

    logout() {
        return Parse.User.logOut().then(() => {
            this.user.next(Parse.User.current());
            localStorage.removeItem('user');
            this.router.navigate(['/']);
        }).catch((error) => {
            return { error };
        });
    }

    /**
     * [resetPassword description]
     * This function will take the user's email address and send a password reset link, then Firebase will handle the
     * email reset part, you won't have to do anything else.
     *
     * @param  {string} email    [User's email address]
     */
    async resetPassword(email: string): Promise<any> {


            return Parse.Cloud.run('sendPasswordResetEmail', {
                'email': email
            });

        // return Parse.User.requestPasswordReset(email).then(() => true).catch((error) => {
        //     return { error };
        // });
    }

    async createUser(signupUserInfo: SignupUserInfo): Promise<any> {

        let user = new Parse.User();
        user.set('username', signupUserInfo.email);
        user.set('password', signupUserInfo.password);
        user.set('email', signupUserInfo.email);

        // other fields can be set just like with Parse.Object
        user.set('name', signupUserInfo.name);
        user.set('surname', signupUserInfo.surname);

        return user.signUp().then((user) => {
            this.user.next(Parse.User.current());
            // Check if user has invites
            const invitesObject = Parse.Object.extend('Invites');
            const query = new Parse.Query(invitesObject);
            query.equalTo('email', signupUserInfo.email);
            query.find().then((invites: Parse.Object<Parse.Attributes>[]) => {
                invites.forEach((invite: Parse.Object<Parse.Attributes>) => {
                    // TODO: Update
                    // this.addUserToFarms(newUser, invite.get('role'), [invite.get('farms')]);
                });
            });

        }).catch((error) => {
            console.error("Error: " + error.code + " " + error.message);
            return { error };
        });
    }

    async getAllAnimals(): Promise<Animal[]> {
        const animals = [];

        const animalObject = Parse.Object.extend('Animal');
        const query = new Parse.Query(animalObject);
        return query.find().then((animalList: Parse.Object<Parse.Attributes>[]) => {
            animalList.forEach(doc => {
                const animal: Animal = {
                    name: doc.get('name'),
                    id: doc.id,
                    image: 'assets/animals/' + doc.get('image'),
                    color: doc.get('color')
                }
                animals.push(animal);
            });
            return animals;
        });
    }

    async loadPricingScales(): Promise<BillingScale[]> {
        const billingScales: BillingScale[] = [];
        const billingObject = Parse.Object.extend('BillingScale');
        const query = new Parse.Query(billingObject);
        return query.find().then((results: Parse.Object<Parse.Attributes>[]) => {
            results.forEach(doc => {
                const scale: BillingScale = {
                    scale_end: doc.get('end'),
                    scale_start: doc.get('start'),
                    scale_fee: doc.get('fee')
                }
                billingScales.push(scale);
            });
            return Promise.resolve(billingScales);
        });
    }

    async createFarm(farm: UserFarm): Promise<{ farm: UserFarm, id: string }> {
        let users = [];
        users.push({
            id: Parse.User.current().id,
            name: Parse.User.current().get('name'),
            surname: Parse.User.current().get('surname'),
            role: RoleType.Admin
        });

        const farmsObject = Parse.Object.extend('Farms');
        const newFarm: Parse.Object = new farmsObject();

        newFarm.set('name', farm.name);
        newFarm.set('contact_email', farm.email);
        newFarm.set('size', farm.totalFarmSize);
        newFarm.set('override_price', null);
        newFarm.set('customer_id', farm.customerId);
        newFarm.set('species', farm.species);
        newFarm.set('users', users);
        newFarm.set(
            'middle_point',
            new Parse.GeoPoint({
                latitude: farm.middlePoint.lat,
                longitude: farm.middlePoint.lng
            }));

        const farmId = await newFarm.save().then(async (savedFarm) => {

            for (let i = 0; i < farm.layout.length; i++) {
                const pointer = {
                    '__type': 'Pointer',
                    'className': 'Farms',
                    'objectId': savedFarm.id
                };
                const layoutObject = Parse.Object.extend('Layout');
                const newLayout: Parse.Object = new layoutObject();
                newLayout.set('order', i);
                newLayout.set(
                    'point',
                    new Parse.GeoPoint({
                        latitude: farm.layout[i].lat,
                        longitude: farm.layout[i].lng
                    }));
                newLayout.set('farm', pointer);

                await newLayout.save();
            }
            return savedFarm.id;

        });
        let userFarm: UserFarm = {
            name: farm.name,
            email: farm.email,
            createdDate: farm.createdDate,
            users: users,
            totalFarmSize: farm.totalFarmSize,
            species: farm.species,
            layout: farm.layout,
            middlePoint: farm.middlePoint,
            customerId: farm.customerId,
            overridePrice: farm.overridePrice,
            farmCounts: farm.farmCounts,
            paymentMethods: farm.paymentMethods,
            cardAdded: farm.cardAdded,
            paymentMethodIds: farm.paymentMethodIds
        };

        return { farm: userFarm, id: farmId };
    }

    // addCardToFarm(
    //     farmId: string, methodOfPayment: MethodOfPayment): Promise<String> {
    //     return this._firebaseStore
    //         .collection('farms')
    //         .doc(farmId)
    //         .collection('cards')
    //         .add({
    //             paymentMethodId: methodOfPayment.paymentMethodId,
    //             customerId: methodOfPayment.customerId
    //         }).then((dr: DocumentReference) => dr.id);
    // }

    async addUserToFarms(user: User, role: RoleType, farmIds: string[]) {

        const userLink = {
            id: user.id,
            name: user.name,
            surname: user.surname,
            role: role
        };

        const promises = [];

        farmIds.forEach((farm) => {
            const farmsObject = Parse.Object.extend('Farms');
            const farmUpdate = new farmsObject();
            farmUpdate.set('objectId', farm);
            farmUpdate.addUnique('users', userLink);

            promises.push(farmUpdate.save());
        });

        return Promise.all(promises).then((_) => true);
    }

    removeUserFromFarm(user: LinkedUser, farmId: string) {

        const userLink = {
            id: user.id,
            name: user.name,
            surname: user.surname,
            role: user.role
        };

        const farmsObject = Parse.Object.extend('Farms');
        const farmUpdate = new farmsObject();
        farmUpdate.set('objectId', farmId);
        farmUpdate.remove('users', userLink);

        return farmUpdate.save().then((_) => true);
    }

    updateFarm(farm: UserFarm) {

        const farmsObject = Parse.Object.extend('Farms');
        const farmUpdate = new farmsObject();
        farmUpdate.set('objectId', farm.id);
        farmUpdate.set('name', farm.name);
        farmUpdate.set('contact_email', farm.email);
        farmUpdate.set('size', farm.totalFarmSize);
        farmUpdate.set('middle_point',
            new Parse.GeoPoint({
                latitude: farm.middlePoint.lat,
                longitude: farm.middlePoint.lng
            }));
        farmUpdate.set('species', farm.species);
        const newUsers = [];
        farm.users.forEach((user) => {
            newUsers.push({
                'id': user.id,
                'name': user.name,
                'surname': user.surname,
                'role': user.role
            });
        });
        farmUpdate.set('users', newUsers);

        return farmUpdate.save().then(async (_) => {
            // Delete current layout values
            const layoutObject = Parse.Object.extend('Layout');
            const query = new Parse.Query(layoutObject);

            const pointer = {
                '__type': 'Pointer',
                'className': 'Farms',
                'objectId': farm.id
            };
            query.equalTo('farm', pointer);

            await query.find().then((layouts) => {
                layouts.forEach((layout) => {
                    layout.destroy();
                });
            });

            farm.layout.forEach(async (point, index) => {
                const layoutsObject = Parse.Object.extend('Layout');
                const layout = new layoutsObject();
                layout.set('farm', pointer);
                layout.set('order', index);
                layout.set('point', new Parse.GeoPoint({
                    latitude: point.lat,
                    longitude: point.lng
                }));

                await layout.save();
            });

            return farm;
        });
    }

    inviteUserToFarms(email: string, role: RoleType, farmsIds: string[]) {

        const invitesObject = Parse.Object.extend('Invites');
        const invitesUpdate = new invitesObject();
        invitesUpdate.set('email', email);
        invitesUpdate.set('role', role);
        const pointer = {
            '__type': 'Pointer',
            'className': '_User',
            'objectId': Parse.User.current().id
        };
        invitesUpdate.set('invitee', pointer);
        invitesUpdate.set('farms', farmsIds);
        invitesUpdate.set('processed', false);

        return invitesUpdate.save().then((_) => true);
    }

    async getUserFarms(): Promise<UserFarm[]> {
        const users = [];
        if (Parse.User.current() != null && Parse.User.current().id != null) {
            users.push({
                id: Parse.User.current().id,
                name: Parse.User.current().get('name'),
                surname: Parse.User.current().get('surname'),
                role: RoleType.Admin
            });
            users.push({
                id: Parse.User.current().id,
                name: Parse.User.current().get('name'),
                surname: Parse.User.current().get('surname'),
                role: RoleType.Colleague
            });
            users.push({
                id: Parse.User.current().id,
                name: Parse.User.current().get('name'),
                surname: Parse.User.current().get('surname'),
                role: RoleType.Viewer
            });
        } else {
            users.push({
                role: RoleType.Viewer,
                surname: 'Initial',
                name: 'None',
                id: 1
            });
        }

        // let farmCounts: Count[] = new Array<Count>();
        // let paymentMethods: MethodOfPayment[] = [];

        const farmsObject = Parse.Object.extend('Farms');
        const query = new Parse.Query(farmsObject);
        query.containedIn('users', users);
        let farmList: Array<UserFarm> = new Array<UserFarm>();
        farmList = Object.assign([], farmList);
        return query.find().then(async (farms) => {
            await farms.forEach(async (farmVal) => {
                let users = [];

                await farmVal.get('users').forEach((user) => {
                    const userVal = {
                        id: user.id,
                        name: user.name,
                        surname: user.surname,
                        role: user.role,
                        selectedRoles: [{ "id": user.role == 'Administrator' ? 1 : user.role == 'Colleague' ? 2 : 3, "itemName": user.role }]
                    };
                    users.push(userVal);
                });
                const species: string[] = farmVal.get('species');

                const middle = farmVal.get('middle_point');
                const middlePoint: MapLocation = {
                    lat: middle.latitude,
                    lng: middle.longitude
                };

                const farm: UserFarm = {
                    id: farmVal.id,
                    name: farmVal.get('name'),
                    email: farmVal.get('contact_email'),
                    createdDate: farmVal.createdAt,
                    users: users,
                    totalFarmSize: farmVal.get('size'),
                    species: species,
                    layout: [],
                    middlePoint: middlePoint,
                    overridePrice: farmVal.get('override_rice'),
                    farmCounts: [],
                    paymentMethods: [],
                    customerId: farmVal.get('customer_id')
                };
                if (users) {
                    for (const user of users) {
                        if (user.id === Parse.User.current().id) {
                            farmList.push(farm);
                        }
                    }
                }
            });
            return farmList;
        }).then((farms: UserFarm[]) => {
            const promiseList = [];
            farms.forEach(async (farm) => {
                // Load layout
                const layoutObject = Parse.Object.extend('Layout');
                const query = new Parse.Query(layoutObject);
                const pointer = {
                    '__type': 'Pointer',
                    'className': 'Farms',
                    'objectId': farm.id
                };
                query.equalTo('farm', pointer);
                query.ascending('order');
                promiseList.push(query.find().then((layoutVal) => {
                    const layoutList: MapLocation[] = [];
                    layoutVal.forEach((point) => {
                        const maplocation: MapLocation = {
                            lat: point.get('point').latitude,
                            lng: point.get('point').longitude
                        }
                        layoutList.push(maplocation);
                    });
                    farm.layout = layoutList;
                }));
            });
            return Promise.all([...promiseList]).then(() => farms);
        }).then(async (farms: UserFarm[]) => {
            const promiseList = [];
            await farms.forEach(async (farm) => {
                // Load counts and details
                const countsObject = Parse.Object.extend('Counts');
                const queryCounts = new Parse.Query(countsObject);
                const pointerFarm = {
                    '__type': 'Pointer',
                    'className': 'Farms',
                    'objectId': farm.id
                };
                queryCounts.equalTo('farm', pointerFarm);
                queryCounts.equalTo('valid', true);
                queryCounts.equalTo('cancelled', false);
                promiseList.push(queryCounts.find().then(async (counts) => {
                    const countsList: Count[] = [];
                    await counts.forEach(async (count) => {

                        const createdUser: Parse.Pointer = count.get('created_user');
                        const farm: Parse.Pointer = count.get('farm');
                        const paymentVal = count.get('payment');
                        const payment: Payment = {
                            error: paymentVal?.error,
                            clientSecret: paymentVal?.client_secret,
                            paymentIntentId: paymentVal?.payment_intent_id,
                            paymentSetupIntentId: paymentVal?.payment_setup_intent_id,
                            nextAction: paymentVal?.next_action
                        };
                        const countVal: Count = {
                            id: count.id,
                            userCreated: createdUser.objectId,
                            startDate: count.createdAt,
                            completedDate: count.get('completed_date') ? count.get('completed_date') : null,
                            farmId: farm.objectId,
                            farmName: count.get('farm_name'),
                            paid: count.get('paid'),
                            paidDate: count.get('paid_date') ? count.get('paid_date') : null,
                            orderId: count.get('order_id'),
                            countDetails: [],
                            payment: payment ? payment[0] : null,
                            linkedUserCounts: []
                        };
                        countsList.push(countVal);
                    });
                    farm.farmCounts = countsList;
                }));
            });
            return Promise.all([...promiseList]).then(() => farms);
        }).then((farms: UserFarm[]) => {
            const promiseList = [];
            farms.forEach((farm) => {
                farm.farmCounts.forEach((count) => {

                    // Load details for count
                    const countDetailsObject = Parse.Object.extend('CountDetails');
                    const queryDetails = new Parse.Query(countDetailsObject);
                    queryDetails.limit(1000);
                    const pointerCount = {
                        '__type': 'Pointer',
                        'className': 'Counts',
                        'objectId': count.id
                    };
                    queryDetails.equalTo('count', pointerCount);
                    promiseList.push(queryDetails.find().then(async (countDetailsVal) => {
                        const detailList: CountDetail[] = [];
                        await countDetailsVal.forEach(async (detail) => {
                            const mapLocation: Parse.GeoPoint = detail.get('location');
                            const location: MapLocation = {
                                lat: mapLocation.latitude,
                                lng: mapLocation.longitude
                            };
                            const user: Parse.Pointer = detail.get('user');
                            const animal: Parse.Pointer = detail.get('animal');
                            const countDetail: CountDetail = {
                                id: detail.id,
                                animalName: detail.get('animal_name'),
                                animalId: animal.id,
                                maleCount: detail.get('male'),
                                femaleCount: detail.get('female'),
                                unknownCount: detail.get('unknown'),
                                maleAge: '',
                                femaleAge: '',
                                unknownAge: '',
                                date: detail.get('date'),
                                user: user.id,
                                userName: detail.get('user_name'),
                                location: location,
                                countInFarm: detail.get('in_farm')
                            };
                            detailList.push(countDetail);
                        });
                        count.countDetails = detailList;
                    }));
                });
            });
            return Promise.all([...promiseList]).then(() => farms);
        }).then((farms: UserFarm[]) => {
            const promiseList = [];
            farms.forEach((farm) => {
                farm.farmCounts.forEach((count) => {
                    // Load count users
                    const pointerCount = {
                        '__type': 'Pointer',
                        'className': 'Counts',
                        'objectId': count.id
                    };
                    const countUsersObject = Parse.Object.extend('CountUsers');
                    const queryCountUsers = new Parse.Query(countUsersObject);
                    queryCountUsers.equalTo('count', pointerCount);
                    promiseList.push(queryCountUsers.find().then(async (users) => {
                        const usersList: LinkedUserCount[] = [];

                        await users.forEach(async (linkedUser) => {
                            const user: Parse.Pointer = linkedUser.get('user');
                            const linkedUserCount: LinkedUserCount = {
                                added: linkedUser.get('added'),
                                id: user.objectId,
                                name: linkedUser.get('name'),
                                surname: linkedUser.get('surname'),
                                role: linkedUser.get('role'),
                                completedCount: linkedUser.get('completed'),
                                completedDate: linkedUser.get('completed_date')
                            };
                            usersList.push(linkedUserCount);
                        });

                        count.linkedUserCounts = usersList;
                    }));
                });
            });
            return Promise.all([...promiseList]).then(() => farms);
        });

    }

    completeCount(countId: string): Promise<string> {

        const countsObject = Parse.Object.extend('Counts');
        const count = new countsObject();
        count.set('objectId', countId);
        count.set('completedDate', Date.now());
        count.set('completed', true);

        return count.save().then((_) => {
            return countId;
        });
    }

    paidForCount(countId: string): Promise<String> {

        const countsObject = Parse.Object.extend('Counts');
        const count = new countsObject();
        count.set('objectId', countId);
        count.set('paid_date', Date.now());
        count.set('paid', true);

        return count.save().then((_) => {
            return countId;
        });
    }

    async getAllUsers(): Promise<User[]> {

        const userObject = Parse.Object.extend('User');
        const query = new Parse.Query(userObject);
        return query.find().then((usersVal) => {
            const users: User[] = [];
            usersVal.forEach(async (userDetail) => {
                const user: User = {
                    id: userDetail.id,
                    name: userDetail.get('name'),
                    surname: userDetail.get('surname'),
                    email: userDetail.get('email'),
                    device_id: ''
                };
                users.push(user);
            });
            return users;
        });
    }

    createPayment(amount: number, countId: string, description: string) {

        const countsObject = Parse.Object.extend('Counts');
        const count = new countsObject();
        count.set('objectId', countId);
        count.set('payment', {
            'amount': amount,
            'description': description,
            'currency': 'zar'
        });

        return count.save();
    }




    // Call Functions
    sendContactUsEmail(name: string, email: string, message: string) {
        return Parse.Cloud.run('contactUsEmail', {
            'name': name, 'email': email,
            'message': message,
            'site': 'GameSurv'
        });
    }


    resendResultsEmail(farmId: string, countId: string) {
        return Parse.Cloud.run('resendResultsEmail', {
            'farmId': farmId,
            'countId': countId
        });
    }

    removeCustomerCard(customerId: string, paymentMethodId: string) {
        return Parse.Cloud.run('removeCustomerCard', {
            'customerId': customerId,
            'paymentMethodId': paymentMethodId
        });
    }

    

}