import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  map,
  catchError,
  tap,
  withLatestFrom,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { of, Observable, from } from 'rxjs';
import {
  UserActionTypes,
  LOGOUT_USER,
  LOGIN_USER,
  LOAD_USER,
  LOGIN_USER_SUCCESS,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAILED,
  LOGIN_USER_FAILED,
  LOAD_ALL_USERS,
  LOAD_ALL_USERS_SUCCESS,
  LOAD_ALL_USERS_FAILED,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  NEW_LOGIN_USER,
  LOGIN_USER_SUCCESS_AND_MOVE,
} from '../actions/user.actions';
import { User, AppState } from '../../models';
import { Router } from '@angular/router';
import { LOAD_ANIMALS } from '../actions/animals.actions';
import { LOAD_FARMS } from '../actions/farm.actions';
import { ParseServerProvider } from '../../providers/parse-server.provider';
@Injectable({
  providedIn: 'root',
})
export class UserEffects {
  @Effect()
  login$: Observable<any> = this.actions$.pipe(
    ofType<LOGIN_USER>(UserActionTypes.LOGIN),
    map((action: any) => ({ ...action.payload })),
    switchMap((creds) => {
      return from(
        this.parseServerProvider.authenticate(creds.creds).catch((err) => err)
      ).pipe(
        // takeUntil(this.subService.unsubscribe$),
        map((val) => {
          if (val && val.error) {
            let errorMessage = '';
            if (val.error.code === 'auth/wrong-password') {
              errorMessage = 'Invalid username or password supplied.';
            } else if (val.error.code === 'auth/too-many-requests') {
              errorMessage =
                'Too many unsuccessful login attempts. Please wait 5 min and try again.';
            }
            return new LOGIN_USER_FAILED({ error: errorMessage });
          } else {
            return new LOGIN_USER_SUCCESS_AND_MOVE();
          }
        }),
        catchError((err) => of(new LOGIN_USER_FAILED({ error: err.message })))
      );
    })
  );

  @Effect()
  newLogin$: Observable<any> = this.actions$.pipe(
    ofType<NEW_LOGIN_USER>(UserActionTypes.NEW_LOGIN),
    map((action: any) => ({ ...action.payload })),
    switchMap((creds) => {
      return from(
        this.parseServerProvider.authenticate(creds.creds).catch((err) => err)
      ).pipe(
        // takeUntil(this.subService.unsubscribe$),
        map((val) => {
          console.error(val);
          if (val && val.error) {
            let errorMessage = '';
            if (val.error.code === 'auth/wrong-password') {
              errorMessage = 'Invalid username or password supplied.';
            } else if (val.error.code === 'auth/too-many-requests') {
              errorMessage =
                'Too many unsuccessful login attempts. Please wait 5 min and try again.';
            }
            return new LOGIN_USER_FAILED({ error: errorMessage });
          } else {
            return new LOGIN_USER_SUCCESS();
          }
        }),
        catchError((err) => of(new LOGIN_USER_FAILED({ error: err.message })))
      );
    })
  );

  @Effect()
  register$: Observable<any> = this.actions$.pipe(
    ofType<REGISTER_USER>(UserActionTypes.REGISTER),
    map((action: any) => ({ ...action.payload })),
    switchMap((userInfo) => {
      return from(
        this.parseServerProvider
          .createUser(userInfo.userInfo)
          .catch((err) => err)
      ).pipe(
        // takeUntil(this.subService.unsubscribe$),
        map((val) => {
          if (val && val.error) {
            let errorMessage = '';
            if (val.error.code === 'auth/email-already-in-use') {
              errorMessage = 'The email you provided is already in use.';
            } else if (val.error.code === 'auth/weak-password') {
              errorMessage = 'The password you provided is not strong enough.';
            } else if (val.error.code === 'auth/too-many-requests') {
              errorMessage =
                'Too many unsuccessful login attempts. Please wait 5 min and try again.';
            }
            return new REGISTER_USER_FAILED({ error: errorMessage });
          } else {
            return new REGISTER_USER_SUCCESS();
          }
        }),
        catchError((err) =>
          of(new REGISTER_USER_FAILED({ error: err.message }))
        )
      );
    })
  );

  @Effect()
  resetPassword$: Observable<any> = this.actions$.pipe(
    ofType<RESET_PASSWORD>(UserActionTypes.RESET_PASSWORD),
    map((action: any) => ({ ...action.payload })),
    switchMap((userInfo) => {
      return from(
        this.parseServerProvider
          .resetPassword(userInfo.email)
          .catch((err) => err)
      ).pipe(
        // takeUntil(this.subService.unsubscribe$),
        map((val) => {
          console.log(val);
          if (val.error) {
            // let errorMessage = '';
            // if (val.error.code === 'auth/user-not-found') {
            //     errorMessage = 'We are not able to find a user linked to the email address.'
            // } else if (val.error.code === 'auth/too-many-requests') {
            //     errorMessage = 'Too many unsuccessful login attempts. Please wait 5 min and try again.'
            // }
            return new RESET_PASSWORD_FAILED({ error: val.error });
          } else {
            return new RESET_PASSWORD_SUCCESS({ result: val });
          }
        }),
        catchError((err) =>
          of(new RESET_PASSWORD_FAILED({ error: err.message }))
        )
      );
    })
  );

  @Effect()
  logOut$: Observable<Action> = this.actions$.pipe(
    ofType<LOGOUT_USER>(UserActionTypes.LOGOUT),
    map((action: any) => ({ ...action.payload })),
    // withLatestFrom(this.store.select('app')),
    switchMap(([action, state]) => {
      const user = state.current_user;
      // this.parseServerProvider.updateUser(user);
      return from(this.parseServerProvider.logout()).pipe(
        // takeUntil(this.subService.unsubscribe$),
        map((authData: any) => {
          if (authData && authData.error) throw authData.error;
          return { type: UserActionTypes.LOGOUT_SUCCESS, payload: authData };
        }),
        catchError((err) =>
          of({ type: UserActionTypes.LOGOUT_FAILED, payload: err.message })
        )
      );
    })
  );

  @Effect({ dispatch: false })
  loginSuccess$ = this.actions$.pipe(
    ofType<LOGIN_USER_SUCCESS>(UserActionTypes.LOGIN_SUCCESS),
    tap(() => {
      return this.router.navigate(['/gamesurv/dashboard']);
    })
  );

  @Effect({ dispatch: false })
  loginSuccessAndMove$ = this.actions$.pipe(
    ofType<LOGIN_USER_SUCCESS_AND_MOVE>(UserActionTypes.LOGIN_SUCCESS_AND_MOVE),
    tap(() => {
      return this.router.navigate(['/gamesurv/dashboard']);
    })
  );

  @Effect({ dispatch: false })
  registerSuccess$ = this.actions$.pipe(
    ofType<REGISTER_USER_SUCCESS>(UserActionTypes.REGISTER_SUCCESS),
    tap(() => this.router.navigate(['/gamesurv/dashboard']))
  );

  // @Effect()
  // loadUserDetails$: Observable<any> = this.actions$
  //     .pipe(
  //         ofType<LOAD_USER>(UserActionTypes.LOAD_USER_DETAILS),
  //         // map((action: LOAD_USER) => ({ ...action })),
  //         switchMap(() => {
  //             return from(this.firebaseProvider.getUserDetails()).pipe(
  //                 // takeUntil(this.subService.unsubscribe$),
  //                 map((info: User) => {
  //                     this.store.dispatch(new LOAD_FARMS({ id: info.id }));
  //                     this.store.dispatch(new LOAD_ALL_USERS());
  //                     return new LOAD_USER_SUCCESS({ userData: info });
  //                 }
  //                 ), catchError(err =>
  //                     of(new LOAD_USER_FAILED({ error: err.message }))
  //                 ));
  //         })
  //     );

  @Effect()
  loadAllUsers$: Observable<any> = this.actions$.pipe(
    ofType<LOAD_ALL_USERS>(UserActionTypes.LOAD_ALL_USERS),
    switchMap(() => {
      return from(this.parseServerProvider.getAllUsers()).pipe(
        // takeUntil(this.subService.unsubscribe$),
        map((users: User[]) => {
          return new LOAD_ALL_USERS_SUCCESS({ users: users });
        }),
        catchError((err) =>
          of(new LOAD_ALL_USERS_FAILED({ error: err.message }))
        )
      );
    })
  );

  constructor(
    private actions$: Actions,
    private parseServerProvider: ParseServerProvider,
    private router: Router,
    private store: Store<AppState>
  ) {}
}
