import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap, takeUntil } from 'rxjs/operators';
import { of, Observable, from } from 'rxjs';
import { User, AppState } from '../../models';
import { LOAD_ALL_USER_REQUIRED_DETAILS, MainActionTypes, LOAD_ALL_USER_REQUIRED_DETAILS_SUCCESS, LOAD_ALL_USER_REQUIRED_DETAILS_FAILED } from '../actions/main-actions.actions';

@Injectable({
    providedIn: 'root'
})
export class MainEffects {

    // @Effect()
    // loadAllUserRequiredDetails$: Observable<any> = this.actions$
    //     .pipe(
    //         ofType<LOAD_ALL_USER_REQUIRED_DETAILS>(MainActionTypes.LOAD_ALL_USER_REQUIRED_DETAILS),
    //         switchMap(() => {
    //             return from(this.dataService.loadAllUserRequiredDetails()).pipe(
    //                 // takeUntil(this.subService.unsubscribe$),
    //                 map((info: AppState) => {
    //                     return new LOAD_ALL_USER_REQUIRED_DETAILS_SUCCESS({ requiredInfo: info });
    //                 }), catchError(err =>
    //                     of(new LOAD_ALL_USER_REQUIRED_DETAILS_FAILED({ error: err.message }))
    //                 ));
    //         })
    //     );

        constructor(private actions$: Actions) { }

    }