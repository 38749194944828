import { Action } from '@ngrx/store';
import { Animal, AnimalCountForm, Count, CountDetail, CurrentCount, FarmSection, NewCount } from '../../models';

export enum CountActionTypes {
   
    COMPLETE_COUNT = '[Count Page] Complete Count',
    COMPLETE_COUNT_SUCCESS = '[Count Page] Complete Count Success',
    COMPLETE_COUNT_FAILED = '[Count Page] Complete Count Error',
    RESEND_EMAIL_RESULTS = '[Counts Page] Resend Count Email Results',
    RESEND_EMAIL_RESULTS_SUCCESS = '[Counts Page] Resend Count Email Results Success',
    RESEND_EMAIL_RESULTS_FAILED = '[Counts Page] Resend Count Email Results Failed',
    PAID_FOR_COUNT = '[Counts Page] Paid For Count',
    PAID_FOR_COUNT_SUCCESS = '[Counts Page] Paid For Count Success',
    PAID_FOR_COUNT_FAILED = '[Counts Page] Paid For Count Failed'

}

export class CountAction implements Action {
    type: string;
    payload: {
        new_count: NewCount;
        count_details: CountDetail[];
        count_animal_form: AnimalCountForm,
        area: FarmSection;
        error: string;
        completed_date: Date;
        started_date: Date;
        animal: Animal;
        gender: string;
        amount: number;
        is_valid: boolean;
    };
}

export class COMPLETE_COUNT implements Action {
    readonly type = CountActionTypes.COMPLETE_COUNT;

    constructor(public payload: { order_id: string; count_id: string }) { }
}

export class COMPLETE_COUNT_SUCCESS implements Action {
    readonly type = CountActionTypes.COMPLETE_COUNT_SUCCESS;
}

export class COMPLETE_COUNT_FAILED implements Action {
    readonly type = CountActionTypes.COMPLETE_COUNT_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class RESEND_EMAIL_RESULTS implements Action {
    readonly type = CountActionTypes.RESEND_EMAIL_RESULTS;

    constructor(public payload: { farmId: string; countId: string }) { }
}

export class RESEND_EMAIL_RESULTS_SUCCESS implements Action {
    readonly type = CountActionTypes.RESEND_EMAIL_RESULTS_SUCCESS;
}

export class RESEND_EMAIL_RESULTS_FAILED implements Action {
    readonly type = CountActionTypes.RESEND_EMAIL_RESULTS_FAILED;

    constructor(public payload: { error: string; }) { }
}

export class PAID_FOR_COUNT implements Action {
    readonly type = CountActionTypes.PAID_FOR_COUNT;

    constructor(public payload: { farmId: string; countId: string }) { }
}

export class PAID_FOR_COUNT_SUCCESS implements Action {
    readonly type = CountActionTypes.PAID_FOR_COUNT_SUCCESS;
}

export class PAID_FOR_COUNT_FAILED implements Action {
    readonly type = CountActionTypes.PAID_FOR_COUNT_FAILED;

    constructor(public payload: { error: string; }) { }
}

export type CountActionsUnion = COMPLETE_COUNT | COMPLETE_COUNT_SUCCESS | COMPLETE_COUNT_FAILED
| RESEND_EMAIL_RESULTS | RESEND_EMAIL_RESULTS_SUCCESS | RESEND_EMAIL_RESULTS_FAILED
| PAID_FOR_COUNT | PAID_FOR_COUNT_SUCCESS | PAID_FOR_COUNT_FAILED;