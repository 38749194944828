import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ToasterComponent } from './shared/components/toaster/toaster.component';
import { CookiePopupComponent } from './shared/components/cookie-popup/cookie-popup.component';
import { environment } from '../environments/environment';

import { sharedProviders } from './shared/providers';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { AuthGuard } from './shared/services/auth-guard.service';

// NGRX
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { mainAppStoreReducer } from './shared/state/reducers';
import { CountEffects } from './shared/state/effects/count.effects';
import { AnimalsEffects } from './shared/state/effects/animals.effects';
import { UserEffects } from './shared/state/effects/user.effects';
import { FarmEffects } from './shared/state/effects/farm.effects';
import { MainEffects } from './shared/state/effects/main.effects';

import { AgmCoreModule } from '@agm/core';
import { NgwWowModule } from 'ngx-wow';
import { NoContentComponent } from './pages/no-content/no-content.component';

@NgModule({
  declarations: [
    AppComponent,
    NoContentComponent,
    CookiePopupComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(),
    AngularFireFunctionsModule,
    AppRoutingModule,
    NgwWowModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      toastComponent: ToasterComponent
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCQw64gEh80ruR5D6QzG28h-OVSym3ZS_c',
      libraries: ['drawing']
    }),
    StoreModule.forRoot({ app: mainAppStoreReducer }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([CountEffects, AnimalsEffects, UserEffects, FarmEffects, MainEffects]),
  ],
  providers: [AuthGuard, CookieService, ...sharedProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
