// import { FirebaseProvider } from '../providers/firebase.provider';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ParseServerProvider } from '../providers/parse-server.provider';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private parseServerProvider: ParseServerProvider) { }

    canActivate() {
        if ( this.parseServerProvider.isLoggedIn() ) {
            return true;
        }
        // this.router.navigate(['/gamesurv']);
        return false;
    }
}