import {Component} from '@angular/core';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import { Toast, ToastrService, ToastPackage  } from 'ngx-toastr';

@Component({
  // TSLint override for now
  // tslint:disable-next-line:component-selector
  selector: '[app-toaster]',
  templateUrl: './toaster.component.html',
  animations: [
    trigger('flyInOut', [
    state('inactive', style({
      opacity: 0,
    })),
    transition('inactive => active', animate('400ms ease-out', keyframes([
      style({
        transform: 'translate3d(-100%, 0, 0) skewX(-5deg)',
        opacity: 0,
      }),
      style({
        transform: 'skewX(10deg)',
        opacity: 1,
      }),
      style({
        transform: 'skewX(-3deg)',
        opacity: 1,
      }),
      style({
        transform: 'none',
        opacity: 1,
      }),
    ]))),
    transition('active => removed', animate('400ms ease-out', keyframes([
      style({
        opacity: 1,
      }),
      style({
        transform: 'translate3d(-100%, 0, 0) skewX(15deg)',
        opacity: 0,
      }),
    ]))),
  ])],
  preserveWhitespaces: false,
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent extends Toast {
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }
}
