import { Action } from '@ngrx/store';
import { Animal } from '../../models/animal.model';

export enum AnimalsActionTypes {
    LOAD_ANIMALS = '[Home Page] Load Animals',
    LOAD_ANIMALS_SUCCESS = '[Home Page] Load Animals Success',
    LOAD_ANIMALS_FAILED = '[Home Page] Load Animals Error'
}

export class AnimalsAction implements Action {
    type: string;
    payload: {
        animals: Animal[];
        error: string;
    };
}

export class LOAD_ANIMALS implements Action {
    readonly type = AnimalsActionTypes.LOAD_ANIMALS;

}

export class LOAD_ANIMALS_SUCCESS implements Action {
    readonly type = AnimalsActionTypes.LOAD_ANIMALS_SUCCESS;

    constructor(public payload: { animals: Animal[] }) { }
}

export class LOAD_ANIMALS_FAILED implements Action {
    readonly type = AnimalsActionTypes.LOAD_ANIMALS_FAILED;

    constructor(readonly payload: {error: string}) {
    }
  }

  export type AnimalsActionsUnion = LOAD_ANIMALS | LOAD_ANIMALS_SUCCESS | LOAD_ANIMALS_FAILED;
