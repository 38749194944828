
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { of, Observable, from } from 'rxjs';
import { LOAD_FARMS, LOAD_FARMS_FAILED, LOAD_FARMS_SUCCESS, FarmActionTypes, CREATE_FARM_SUCCESS, CREATE_FARM_FAILED, CREATE_FARM, LOAD_COUNTS, LOAD_PRICING_SCALES, LOAD_PRICING_SCALES_SUCCESS, LOAD_PRICING_SCALES_FAILED, ADD_USER_TO_FARMS, ADD_USER_TO_FARMS_SUCCESS, ADD_USER_TO_FARMS_FAILED, INVITE_USER_TO_FARMS, INVITE_USER_TO_FARMS_SUCCESS, INVITE_USER_TO_FARMS_FAILED, UPDATE_FARM, UPDATE_FARM_SUCCESS, UPDATE_FARM_FAILED, REMOVE_USER_FROM_FARM, REMOVE_USER_FROM_FARM_SUCCESS, REMOVE_USER_FROM_FARM_FAILED, REMOVE_CARD_FROM_FARM, REMOVE_CARD_FROM_FARM_SUCCESS, REMOVE_CARD_FROM_FARM_FAILED } from '../actions/farm.actions';
import { UserFarm, AppState, MethodOfPayment } from '../../models';
import { FirebaseProvider } from '../../providers/firebase.provider';
import { BillingScale } from '../../models/billing_scale.model';
import { RoleType } from '../../models/enums/RoleType';

import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { ParseServerProvider } from '../../providers/parse-server.provider';

@Injectable({
    providedIn: 'root'
})
export class FarmEffects {

    @Effect()
    loadUserFarms$: Observable<Action> = this.actions$
        .pipe(
            ofType<LOAD_FARMS>(FarmActionTypes.LOAD_USER_FARMS),
            switchMap((action) => {
                return from(this.parseServerProvider.getUserFarms()).pipe(
                    // takeUntil(this.subService.unsubscribe$),
                    map((farms: UserFarm[]) => {
                        // Only load farms that are allowed to look at
                        const allowedFarms: UserFarm[] = [];
                        for (const farm of farms) {
                            for (const user of farm.users) {
                                if (user.id === action.payload.id) {
                                    if (user.role === RoleType.Admin || user.role === RoleType.Colleague) {
                                        allowedFarms.push(farm);
                                    }
                                }
                            }
                        }
                        this.store.dispatch(new LOAD_COUNTS({ farms: allowedFarms }));
                        return new LOAD_FARMS_SUCCESS({ farms: farms });
                    }),
                    catchError(err =>
                        of(new LOAD_FARMS_FAILED({ error: err.message }))
                    )
                );
            })
        );

    // @Effect()
    // addCardToFarm$: Observable<Action> = this.actions$
    //     .pipe(
    //         ofType<ADD_CARD_TO_FARM>(FarmActionTypes.ADD_CARD_TO_FARM),
    //         switchMap((action) => {
    //             return from(this.parseServerProvider.addCardToFarm(action.payload.farmId, action.payload.paymentMethod)).pipe(
    //                 // takeUntil(this.subService.unsubscribe$),
    //                 map((result: string) => {
    //                     return new ADD_CARD_TO_FARM_SUCCESS({ farmId: action.payload.farmId, paymentMethod: action.payload.paymentMethod });
    //                 }),
    //                 catchError(err =>
    //                     of(new ADD_CARD_TO_FARM_FAILED({ error: err.message }))
    //                 )
    //             );
    //         })
    //     );


    @Effect()
    createFarm$: Observable<Action> = this.actions$
        .pipe(
            ofType<CREATE_FARM>(FarmActionTypes.CREATE_FARM),
            switchMap((action) => {
                return from(this.parseServerProvider.createFarm(action.payload.farm)).pipe(
                    map((result: { farm: UserFarm, id: string }) => {
                        let newFarm: UserFarm = {
                            name: result.farm.name,
                            email: result.farm.email,
                            createdDate: result.farm.createdDate,
                            users: result.farm.users,
                            totalFarmSize: result.farm.totalFarmSize,
                            species: result.farm.species,
                            layout: result.farm.layout,
                            middlePoint: result.farm.middlePoint,
                            customerId: result.farm.customerId,
                            overridePrice: result.farm.overridePrice,
                            farmCounts: result.farm.farmCounts,
                            paymentMethods: result.farm.paymentMethods,
                            cardAdded: result.farm.cardAdded,
                            paymentMethodIds: result.farm.paymentMethodIds,
                            id: result.id
                        };
                        if (newFarm.cardAdded) {
                            const attachPaymentMethod = this.firebaseFunctions.httpsCallable('attachPaymentMethod');
                            newFarm.paymentMethodIds.forEach((paymentMethodId: string) => {
                                attachPaymentMethod({
                                    'paymentMethodId': paymentMethodId,
                                    'customerId': newFarm.customerId,
                                    'farmId': newFarm.id
                                }).toPromise().then((result) => {
                                    const paymentMethod: MethodOfPayment = {
                                        paymentMethodId: paymentMethodId,
                                        customerId: newFarm.customerId
                                    };
                                    newFarm.paymentMethods.push(paymentMethod);
                                });
                            });

                            return new CREATE_FARM_SUCCESS({ farm: newFarm });
                        } else {
                            return new CREATE_FARM_SUCCESS({ farm: newFarm });
                        }
                    }),
                    catchError(err => {
                        console.log(err);
                        return of(new CREATE_FARM_FAILED({ error: err.message }))
                    }

                    )
                );
            })
        );

    @Effect({ dispatch: false })
    createFarmSuccess$ = this.actions$.pipe(
        ofType<CREATE_FARM_SUCCESS>(FarmActionTypes.CREATE_NEW_FARM_SUCCESS),
        tap(() => {
            return this.router.navigate(['/gamesurv/dashboard']);
        })
    );

    @Effect()
    removeCardFromFarm$: Observable<Action> = this.actions$
        .pipe(
            ofType<REMOVE_CARD_FROM_FARM>(FarmActionTypes.REMOVE_CARD_FROM_FARM),
            // map((action: any) => ({ ...action.payload })),
            switchMap((action) => {
                return from(this.parseServerProvider.removeCustomerCard(action.payload.customerId, action.payload.paymentMethodId)).pipe(
                    // takeUntil(this.subService.unsubscribe$),
                    map((response: boolean) => {
                        console.log('Card removed');
                        console.log(response);

                        return new REMOVE_CARD_FROM_FARM_SUCCESS({ result: true });
                    }),
                    catchError(err =>
                        of(new REMOVE_CARD_FROM_FARM_FAILED({ error: err.message }))
                    )
                );
            })
        );

    // @Effect()
    // farmNameChange$: Observable<Action> = this.actions$
    //     .pipe(
    //         ofType<FARM_NAME_CHANGE>(FarmActionTypes.EXISTING_FARM_NAME_CHANGE),
    //         map((action: any) => ({ ...action.payload })),
    //         withLatestFrom(this.store.select('app')),
    //         switchMap(([action, state]) => {
    //             const updatedFarm = state.existing_farm;
    //             updatedFarm.name = action.name;
    //             return from(this.firebaseProvider.updateFarm(updatedFarm)).pipe(
    //                 takeUntil(this.subService.unsubscribe$),
    //                 map((farm: UserFarm) => {
    //                     return new FARM_NAME_CHANGE_SUCCESS({ name: action.name });
    //                 }),
    //                 catchError(err =>
    //                     of(new FARM_NAME_CHANGE_FAILED({ error: err.message }))
    //                 )
    //             );
    //         })
    //     );

    // @Effect()
    // farmEmailChange$: Observable<Action> = this.actions$
    //     .pipe(
    //         ofType<FARM_EMAIL_CHANGE>(FarmActionTypes.EXISTING_FARM_EMAIL_CHANGE),
    //         map((action: any) => ({ ...action.payload })),
    //         withLatestFrom(this.store.select('app')),
    //         switchMap(([action, state]) => {
    //             const updatedFarm = state.existing_farm;
    //             updatedFarm.email = action.email;
    //             return from(this.firebaseProvider.updateFarm(updatedFarm)).pipe(
    //                 takeUntil(this.subService.unsubscribe$),
    //                 map((farm: UserFarm) => {
    //                     return new FARM_EMAIL_CHANGE_SUCCESS({ email: action.name });
    //                 }),
    //                 catchError(err =>
    //                     of(new FARM_EMAIL_CHANGE_FAILED({ error: err.message }))
    //                 )
    //             );
    //         })
    //     );

    // @Effect()
    // farmSpeciesChange$: Observable<Action> = this.actions$
    //     .pipe(
    //         ofType<FARM_SPECIE_CHANGE>(FarmActionTypes.EXISTING_FARM_SPECIE_CHANGE),
    //         map((action: any) => ({ ...action.payload })),
    //         withLatestFrom(this.store.select('app')),
    //         switchMap(([action, state]) => {
    //             const updatedFarm = state.existing_farm;
    //             updatedFarm.species = action.species_selection;
    //             return from(this.firebaseProvider.updateFarm(updatedFarm)).pipe(
    //                 takeUntil(this.subService.unsubscribe$),
    //                 map(() => {
    //                     return new FARM_SPECIE_CHANGE_SUCCESS({ species_selection: action.species_selection });
    //                 }),
    //                 catchError(err =>
    //                     of(new FARM_SPECIE_CHANGE_FAILED({ error: err.message }))
    //                 )
    //             );
    //         })
    //     );

    // @Effect()
    // farmLayoutChange$: Observable<Action> = this.actions$
    //     .pipe(
    //         ofType<FARM_LAYOUT_CHANGE>(FarmActionTypes.EXISTING_FARM_LAYOUT_CHANGE),
    //         map((action: any) => ({ ...action.payload })),
    //         withLatestFrom(this.store.select('app')),
    //         switchMap(([action, state]) => {
    //             const updatedFarm = state.existing_farm;
    //             updatedFarm.layout = action.layout;
    //             updatedFarm.middle_point = action.middle_point;
    //             updatedFarm.total_farm_size = action.section_size;
    //             return from(this.firebaseProvider.updateFarm(updatedFarm)).pipe(
    //                 takeUntil(this.subService.unsubscribe$),
    //                 map(() => {
    //                     return new FARM_LAYOUT_CHANGE_SUCCESS({ layout: action.layout, section_size: action.section_size, middle_point: action.middle_point });
    //                 }),
    //                 catchError(err =>
    //                     of(new FARM_LAYOUT_CHANGE_FAILED({ error: err.message }))
    //                 )
    //             );
    //         })
    //     );

    // @Effect()
    // removeUserLinkRole$: Observable<Action> = this.actions$
    //     .pipe(
    //         ofType<REMOVE_USER_LINK>(FarmActionTypes.REMOVE_USER_LINK),
    //         map((action: any) => ({ ...action.payload })),
    //         withLatestFrom(this.store.select('app')),
    //         switchMap(([action, state]) => {
    //             // Remove user from exiting farm
    //             const updatedExistingFarm = state.existing_farm;
    //             updatedExistingFarm.users = state.existing_farm.users.filter((user) => user.user_id !== action.user_id);
    //             return from(this.firebaseProvider.updateFarm(updatedExistingFarm)).pipe(
    //                 takeUntil(this.subService.unsubscribe$),
    //                 map((farm: UserFarm) => {
    //                     return new REMOVE_USER_LINK_SUCCESS({ farm: farm });
    //                 }),
    //                 catchError(err =>
    //                     of(new REMOVE_USER_LINK_FAILED({ error: err.message }))
    //                 )
    //             );
    //         })
    //     );

    // @Effect()
    // addUserToFarm$: Observable<Action> = this.actions$
    //     .pipe(
    //         ofType<ADD_USER_TO_FARM>(FarmActionTypes.ADD_USER_TO_FARM),
    //         map((action: any) => ({ ...action.payload })),
    //         withLatestFrom(this.store.select('app')),
    //         switchMap(([action, state]) => {
    //             // add user from exiting farm
    //             const newUser: LinkedUser = {
    //                 user_id: action.user.user_id,
    //                 role: RoleType.Viewer,
    //                 name: action.user.name,
    //                 surname: action.user.surname,
    //                 email: action.user.email,
    //                 device_id: ''
    //             }
    //             const updatedExistingFarm = state.existing_farm;
    //             updatedExistingFarm.users.push(newUser);
    //             return from(this.firebaseProvider.updateFarm(updatedExistingFarm)).pipe(
    //                 takeUntil(this.subService.unsubscribe$),
    //                 map((farm: UserFarm) => {
    //                     return new ADD_USER_TO_FARM_SUCCESS({ farm: farm });
    //                 }),
    //                 catchError(err =>
    //                     of(new ADD_USER_TO_FARM_FAILED({ error: err.message }))
    //                 )
    //             );
    //         })
    //     );

    @Effect()
    addUserToFarms$: Observable<Action> = this.actions$
        .pipe(
            ofType<ADD_USER_TO_FARMS>(FarmActionTypes.ADD_USER_TO_FARMS),
            map((action: any) => ({ ...action.payload })),
            switchMap((action) => {

                return from(this.parseServerProvider.addUserToFarms(action.user, action.role, action.farms)).pipe(
                    map((results: boolean) => {
                        return new ADD_USER_TO_FARMS_SUCCESS({ user: action.user, role: action.role, farms: action.farms });
                    }),
                    catchError(err =>
                        of(new ADD_USER_TO_FARMS_FAILED({ error: err.message }))
                    )
                );
            })
        );

    @Effect()
    removeUserFromFarm$: Observable<Action> = this.actions$
        .pipe(
            ofType<REMOVE_USER_FROM_FARM>(FarmActionTypes.REMOVE_USER_FROM_FARM),
            map((action: any) => ({ ...action.payload })),
            switchMap((action) => {

                return from(this.parseServerProvider.removeUserFromFarm(action.users, action.farm)).pipe(
                    map((results: boolean) => {
                        return new REMOVE_USER_FROM_FARM_SUCCESS({ users: action.users, farm: action.farm });
                    }),
                    catchError(err =>
                        of(new REMOVE_USER_FROM_FARM_FAILED({ error: err.message }))
                    )
                );
            })
        );

    @Effect()
    inviteUserToFarms$: Observable<Action> = this.actions$
        .pipe(
            ofType<INVITE_USER_TO_FARMS>(FarmActionTypes.INVITE_USER_TO_FARMS),
            map((action: any) => ({ ...action.payload })),
            switchMap((action) => {
                return from(this.parseServerProvider.inviteUserToFarms(action.email, action.role, action.farms)).pipe(
                    map((results: boolean) => {
                        return new INVITE_USER_TO_FARMS_SUCCESS({ email: action.email, role: action.role, farms: action.farms });
                    }),
                    catchError(err =>
                        of(new INVITE_USER_TO_FARMS_FAILED({ error: err.message }))
                    )
                );
            })
        );


    @Effect()
    updateFarm$: Observable<Action> = this.actions$
        .pipe(
            ofType<UPDATE_FARM>(FarmActionTypes.UPDATE_FARM),
            map((action: any) => ({ ...action.payload })),
            switchMap((action) => {

                return from(this.parseServerProvider.updateFarm(action.farm)).pipe(
                    map((farm: UserFarm) => {
                        return new UPDATE_FARM_SUCCESS({ farm: action.farm });
                    }),
                    catchError(err =>
                        of(new UPDATE_FARM_FAILED({ error: err.message }))
                    )
                );
            })
        );

    // @Effect()
    // addUserLinkRole$: Observable<Action> = this.actions$
    //     .pipe(
    //         ofType<ADD_USER_LINK>(FarmActionTypes.ADD_USER_LINK),
    //         map((action: any) => ({ ...action.payload })),
    //         withLatestFrom(this.store.select('app')),
    //         switchMap(([action, state]) => {
    //             // add user from exiting farm
    //             const newUser: LinkedUser = {
    //                 user_id: action.user_id,
    //                 role: RoleType.Requested,
    //                 name: state.current_user.name,
    //                 surname: state.current_user.surname,
    //                 email: state.current_user.email,
    //                 device_id: ''
    //             }
    //             const updatedExistingFarm = state.existing_farm;
    //             updatedExistingFarm.users.push(newUser);
    //             return from(this.firebaseProvider.updateFarm(updatedExistingFarm)).pipe(
    //                 takeUntil(this.subService.unsubscribe$),
    //                 map((farm: UserFarm) => {
    //                     return new ADD_USER_LINK_SUCCESS({ farm: farm });
    //                 }),
    //                 catchError(err =>
    //                     of(new ADD_USER_LINK_FAILED({ error: err.message }))
    //                 )
    //             );
    //         })
    //     );

    // @Effect()
    // inviteUserToFarm$: Observable<Action> = this.actions$
    //     .pipe(
    //         ofType<INVITE_USER_TO_FARM>(FarmActionTypes.INVITE_USER_TO_FARM),
    //         map((action: any) => ({ ...action.payload })),
    //         withLatestFrom(this.store.select('app')),
    //         switchMap(([action, state]) => {
    //             this.emailService.inviteUser(action.user.email, state.existing_farm.id, state.current_user.name);
    //             // add user from exiting farm
    //             const newUser: LinkedUser = {
    //                 user_id: action.user.user_id,
    //                 role: RoleType.Viewer,
    //                 name: action.user.name,
    //                 surname: action.user.surname,
    //                 email: action.user.email,
    //                 device_id: ''
    //             }
    //             const updatedExistingFarm = state.existing_farm;
    //             updatedExistingFarm.users.push(newUser);
    //             return from(this.firebaseProvider.updateFarm(updatedExistingFarm)).pipe(
    //                 takeUntil(this.subService.unsubscribe$),
    //                 map((farm: UserFarm) => {
    //                     return new INVITE_USER_TO_FARM_SUCCESS({ farm: farm });
    //                 }),
    //                 catchError(err =>
    //                     of(new INVITE_USER_TO_FARM_FAILED({ error: err.message }))
    //                 )
    //             );
    //         })
    //     );

    // @Effect()
    // updateUserRole$: Observable<Action> = this.actions$
    //     .pipe(
    //         ofType<UPDATE_USER_ROLE>(FarmActionTypes.UPDATE_USER_ROLE),
    //         withLatestFrom(this.store.select('app')),
    //         switchMap(([action, state]) => {
    //             return from(this.firebaseProvider.updateFarm(state.existing_farm)).pipe(
    //                 // takeUntil(this.subService.unsubscribe$),
    //                 map((farm: UserFarm) => {
    //                     return new UPDATE_USER_ROLE_SUCCESS({ farm: farm });
    //                 }),
    //                 catchError(err =>
    //                     of(new UPDATE_USER_ROLE_FAILED({ error: err.message }))
    //                 )
    //             );
    //         })
    //     );

    @Effect()
    loadPricingScales$: Observable<Action> = this.actions$
        .pipe(
            ofType<LOAD_PRICING_SCALES>(FarmActionTypes.LOAD_PRICING_SCALES),
            switchMap((action) => {
                return from(this.parseServerProvider.loadPricingScales()).pipe(
                    // takeUntil(this.subService.unsubscribe$),
                    map((scales: BillingScale[]) => {
                        return new LOAD_PRICING_SCALES_SUCCESS({ scales: scales });
                    }),
                    catchError(err =>
                        of(new LOAD_PRICING_SCALES_FAILED({ error: err.message }))
                    )
                );
            })
        );

    constructor(private actions$: Actions, private firebaseProvider: FirebaseProvider, private parseServerProvider: ParseServerProvider, private router: Router, private store: Store<AppState>, private firebaseFunctions: AngularFireFunctions) { }

}
