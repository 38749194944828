import { Component, OnInit } from '@angular/core';
import { DataService } from 'angular2-multiselect-dropdown/lib/multiselect.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-popup',
  templateUrl: 'cookie-popup.component.html'
})

export class CookiePopupComponent implements OnInit {

  showCookie: boolean = false;
  interval;
  
  constructor(private cookieService: CookieService) {
  }

  ngOnInit() {
   this.interval = setInterval(() => this.checkCookie(), 1500);
  }

  checkCookie() {
      this.showCookie = !this.cookieService.check('gamesurv_privacycheck');
  }

  acceptCookie() {
    const today = new Date()
    const cookieExpiryDate = new Date(today);
    cookieExpiryDate.setDate(cookieExpiryDate.getDate() + 1);
    cookieExpiryDate.setHours(2,0,0,0);

    this.cookieService.set('gamesurv_privacycheck', `Accepted privacy policy on ${new Date()}`, { path: '/', expires: cookieExpiryDate, sameSite: 'Lax' });
    this.showCookie = false;
  }
}
