import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EmailValidator } from '../../../shared/validators/email.validator';
import { Credentials } from 'src/app/shared/models/credentials.model';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/shared/models';
import { NEW_LOGIN_USER } from 'src/app/shared/state/actions/user.actions';
import { Observable, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { selectCurrentState } from 'src/app/shared/state/reducers';
import { CLEAR_ERROR } from 'src/app/shared/state/actions/main-actions.actions';

@Component({
    selector: 'app-gamesurv-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {

    currentState$: Observable<AppState> = this.store.pipe(delay(0), select(selectCurrentState));
    private stateSubscription: Subscription;

    public loginForm;

    busyLoggingIn = false;

    error = '';
    showError = false;

    constructor(
        public formBuilder: FormBuilder,
        private store: Store<AppState>
    ) {
        this.busyLoggingIn = false;

        this.loginForm = formBuilder.group({
            email: ['', Validators.compose([Validators.required, EmailValidator.isValid])],
            password: ['', Validators.compose([Validators.minLength(6), Validators.required])]
        });
    }

    ngOnInit() {
        this.stateSubscription = this.currentState$.subscribe((appState: AppState) => {
            if (appState && appState.error) {
                this.busyLoggingIn = false;
                this.showError = true;
                this.error = appState.error;
            }
        });
    }

    ngOnDestroy() {
        this.store.dispatch(new CLEAR_ERROR());
        this.stateSubscription.unsubscribe();
    }

    async loginUser() {
        this.showError = false;
        this.busyLoggingIn = true;
        if (!this.loginForm.valid) {
        } else {
            const creds: Credentials = {
                username: this.loginForm.controls.email.value,
                password: this.loginForm.controls.password.value
            }

            this.store.dispatch(new NEW_LOGIN_USER({ creds: creds }));
        }
    }

}
