import { MetaReducer, createSelector } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { NewCount, AppState, AnimalCountForm, CurrentCount, UserFarm, LinkedUser } from '../../models';
import { AnimalsActionTypes } from '../actions/animals.actions';
import { CountActionTypes } from '../actions/count.actions';

import { UserActionTypes, FarmActionTypes, MainActionTypes } from '../actions/main-actions.actions';
import { RoleType } from '../../models/enums/RoleType';

const newFarmInitial: UserFarm = {
    name: '',
    email: '',
    createdDate: new Date(),
    totalFarmSize: 0,
    users: [],
    species: [],
    layout: [],
    middlePoint: { lat: null, lng: null },
    customerId: '',
    overridePrice: null,
    farmCounts: [],
    paymentMethods: []
};


const currentCountInitial: NewCount = {
    animal: '',
    animal_id: '',
    animal_image: '/assets/dotted.png',
    gender: 'male',
    male_count: null,
    female_count: null,
    unknown_count: null,
    longitude: '',
    latitude: ''
};

const initialCurrentCountState: CurrentCount = {
    current_count: currentCountInitial,
    animal_count_form: getDefaultCountAnimalFormState(),
    count_details: [],
    farm_id: null,
    farm_name: '',
    farm_email: '',
    completed_date: null,
    started_date: null
};

export const intitialState: AppState = {
    users_number: 0,
    app_stats: null,
    auth_checked: true,
    current_user: null,
    user_device_id: null,
    current_count: initialCurrentCountState,
    animals_list: null,
    id: null,
    new_farm: newFarmInitial,
    existing_count: null,
    existing_farm: null,
    user_farm_role: null,
    user_farms_list: [],
    all_farms_list: [],
    all_users_list: [],
    counts_list: [],
    pricing_scales: [],
    loading: false,
    card_removed: false
};

export function getDefaultCountAnimalFormState(): AnimalCountForm {
    return {
        gender: 'male',
        amount_male: null,
        amount_female: null,
        amount_unknown: null,
        is_valid: false,
        is_dirty: false,
        latitude: '',
        longitude: ''
    };
}



export function mainAppStoreReducer(state: AppState = intitialState, action: any) {
    switch (action.type) {
        case MainActionTypes.CLEAR_ERROR: {
            return {
                ...state,
                reset: null,
                error: null
            };
        }

        case MainActionTypes.APP_STAT_USERS: {
            return {
                ...state,
                users_number: action.payload.users,
                error: null,
                loading: true
            };
        }

        case MainActionTypes.APP_STAT_OTHER: {
            return {
                ...state,
                app_stats: action.payload.stats,
                error: null,
                loading: true
            };
        }

        case UserActionTypes.LOGIN: {
            return {
                ...state,
                creds: action.payload,
                error: null,
                loading: true
            };
        }

        case UserActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                error: null,
                loading: false
            };
        }

        case UserActionTypes.LOGIN_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                id: null,
                current_user: null,
                auth_checked: true,
                loading: false
            };
        }

        case UserActionTypes.RESET_PASSWORD_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }

        case UserActionTypes.RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                reset: true,
                loading: false
            };
        }

        case UserActionTypes.REGISTER: {
            return {
                ...state,
                userInfo: action.payload,
                error: null,
                loading: true
            };
        }

        case UserActionTypes.REGISTER_SUCCESS: {
            return {
                ...state,
                error: null,
                loading: false
            };
        }

        case UserActionTypes.REGISTER_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                id: null,
                current_user: null,
                auth_checked: true,
                loading: false
            };
        }

        case MainActionTypes.LOAD_ALL_USER_REQUIRED_DETAILS: {
            return {
                ...state,
                error: null,
                loading: true
            };
        }

        case MainActionTypes.LOAD_ALL_USER_REQUIRED_DETAILS_SUCCESS: {
            return {
                ...state,
                all_farms_list: action.payload.requiredInfo.all_farms_list,
                user_farms_list: action.payload.requiredInfo.user_farms_list,
                all_users_list: action.payload.requiredInfo.all_users_list,
                animals_list: action.payload.requiredInfo.animals_list,
                unpaid_counts: action.payload.requiredInfo.unpaid_counts,
                in_progress_counts: action.payload.requiredInfo.in_progress_counts,
                counts_list: action.payload.requiredInfo.counts_list,
                current_user: action.payload.requiredInfo.current_user,
                pricing_scales: action.payload.requiredInfo.pricing_scales,
                loading: false
            };
        }

        case MainActionTypes.LOAD_ALL_USER_REQUIRED_DETAILS_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }

        case UserActionTypes.LOGOUT: {
            return Object.assign({}, state, { loading: true, auth_checked: false });
        }

        case UserActionTypes.LOGOUT_SUCCESS: {
            return Object.assign({}, intitialState, { auth_checked: true });
        }

        case UserActionTypes.LOGOUT_FAILED: {
            return Object.assign({}, state, {
                error: action.payload.error,
                loading: false
            });
        }

        case UserActionTypes.LOAD_USER_DETAILS: {
            return {
                ...state,
                loading: true
            };
        }

        case UserActionTypes.LOAD_USER_DETAILS_SUCCESS: {
            return {
                ...state,
                current_user: action.payload.userData,
                loading: false
            };
        }
        case UserActionTypes.LOAD_USER_DETAILS_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                current_user: null,
                loading: false
            };
        }

        case UserActionTypes.LOAD_ALL_USERS: {
            return {
                ...state,
                loading: true
            };
        }

        case UserActionTypes.LOAD_ALL_USERS_SUCCESS: {
            return {
                ...state,
                all_users_list: action.payload.users,
                loading: false
            };
        }
        case UserActionTypes.LOAD_ALL_USERS_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                all_users_list: null,
                loading: false
            };
        }

        case FarmActionTypes.LOAD_ALL_FARMS: {
            return {
                ...state,
                loading: true
            };
        }

        case FarmActionTypes.LOAD_ALL_FARMS_SUCCESS: {
            return {
                ...state,
                all_farms_list: action.payload.farms,
                loading: false
            };
        }
        case FarmActionTypes.LOAD_ALL_FARMS_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                all_farms_list: null,
                loading: false
            }
        }

        case FarmActionTypes.LOAD_USER_FARMS: {
            return {
                ...state,
                id: action.payload,
                loading: true
            };
        }

        case FarmActionTypes.LOAD_USER_FARMS_SUCCESS: {
            return {
                ...state,
                user_farms_list: action.payload.farms,
                loading: false
            };
        }
        case FarmActionTypes.LOAD_USER_FARMS_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                user_farms_list: null,
                loading: false
            }
        }

        case FarmActionTypes.LOAD_PRICING_SCALES: {
            return {
                ...state,
                loading: true
            };
        }

        case FarmActionTypes.LOAD_PRICING_SCALES_SUCCESS: {
            return {
                ...state,
                pricing_scales: action.payload.scales,
                loading: false
            };
        }
        case FarmActionTypes.LOAD_PRICING_SCALES_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            }
        }

        case FarmActionTypes.LOAD_USER_COUNTS: {
            return {
                ...state,
                id: action.payload,
                loading: true
            };
        }

        case FarmActionTypes.LOAD_USER_COUNTS_SUCCESS: {
            return {
                ...state,
                counts_list: action.payload.counts,
                loading: false
            };
        }

        case FarmActionTypes.LOAD_USER_COUNTS_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                counts_list: [],
                loading: false
            }
        }

        case FarmActionTypes.CREATE_FARM: {
            return {
                ...state,
                loading: true
            }
        }

        case FarmActionTypes.CREATE_NEW_FARM_SUCCESS: {
            // Need to filter as load user farm details is called before save is done.
            // Thus adding created farm twice
            const newUserFarmList = state.user_farms_list.filter((farm) => farm.id !== action.payload.farm.id);
            newUserFarmList.push(action.payload.farm);
            return {
                ...state,
                new_farm: newFarmInitial,
                user_farms_list: newUserFarmList,
                all_farms_list: [...state.all_farms_list, action.payload.farm],
                existing_farm: action.payload.farm,
                loading: false
            };
        }
        case FarmActionTypes.CREATE_NEW_FARM_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }

        case FarmActionTypes.CREATE_NEW_FARM_NAME_CHANGE: {
            return {
                ...state,
                new_farm: {
                    ...state.new_farm,
                    name: action.payload.name
                },
                loading: false
            };
        }

        case FarmActionTypes.CREATE_NEW_FARM_EMAIL_CHANGE: {
            return {
                ...state,
                new_farm: {
                    ...state.new_farm,
                    email: action.payload.email
                },
                loading: false
            };
        }

        case FarmActionTypes.CREATE_NEW_FARM_SPECIE_CHANGE: {
            return {
                ...state,
                new_farm: {
                    ...state.new_farm,
                    species: action.payload.species_selection,
                },
                loading: false
            };
        }

        case FarmActionTypes.CREATE_NEW_FARM_LAYOUT_CHANGE: {
            return {
                ...state,
                new_farm: {
                    ...state.new_farm,
                    layout: action.payload.layout,
                    middle_point: action.payload.middle_point,
                    total_farm_size: action.payload.section_size
                },
                loading: false
            };
        }
        case FarmActionTypes.CREATE_NEW_FARM_SET_VALIDITY: {
            return {
                ...state,
                new_farm: {
                    ...state.new_farm,
                    is_valid: action.payload.is_valid
                },
                loading: false
            };
        }

        case FarmActionTypes.UPDATE_USER_ROLE: {
            return {
                ...state,
                existing_farm: action.payload.farm,
                loading: true
            };
        }

        case FarmActionTypes.UPDATE_USER_ROLE_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }

        case FarmActionTypes.UPDATE_USER_ROLE_FAILED: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }

        case FarmActionTypes.REMOVE_USER_LINK: {
            return {
                ...state,
                loading: true
            };
        }

        case FarmActionTypes.REMOVE_USER_LINK_SUCCESS: {
            return {
                ...state,
                existing_farm: action.payload.farm,
                loading: false
            };
        }

        case FarmActionTypes.REMOVE_USER_LINK_FAILED: {
            // Remove outdated farm from list and add updated back
            const updatedUserFarms = state.user_farms_list.filter((farm) => farm.id !== action.payload.farm.id);
            updatedUserFarms.push(action.payload.farm);
            return {
                ...state,
                existing_farm: action.payload.farm,
                user_farms_list: updatedUserFarms,
                loading: false
            };
        }

        case FarmActionTypes.ADD_USER_TO_FARMS:
        case FarmActionTypes.UPDATE_FARM_ANIMALS:
        case FarmActionTypes.UPDATE_FARM:
        case FarmActionTypes.INVITE_USER_TO_FARMS:
        case FarmActionTypes.ADD_CARD_TO_FARM: {
            return {
                ...state,
                loading: true
            };
        }

        case FarmActionTypes.ADD_USER_TO_FARMS_SUCCESS: {
            let updatedUserFarms = state.user_farms_list.filter((farm) => !action.payload.farms.includes(farm.id));

            for (let farmId of action.payload.farms) {
                let farm = state.user_farms_list.filter((farm) => farm.id === farmId);
                const linkedUser: LinkedUser = {
                    name: action.payload.user.name,
                    surname: action.payload.user.surname,
                    email: action.payload.user.email,
                    device_id: '',
                    role: action.payload.role,
                    id: action.payload.user.id,
                    selectedRoles: [{ "id": 2, "itemName": action.payload.role}]
                };

                let updatedFarm: UserFarm = {
                    name: 'updated farm',
                    email: '',
                    createdDate: new Date(),
                    users: null,
                    totalFarmSize: 0,
                    species: [],
                    layout: [],
                    middlePoint: {lat: 0, lng: 0},
                    customerId: '',
                    overridePrice: null,
                    farmCounts: [],
                    paymentMethods: []
                };
                Object.assign(updatedFarm, farm[0]);

                const newUsers = [];
                Object.assign(newUsers, farm[0].users);

                newUsers.push(linkedUser);
                updatedFarm.users = newUsers;
                updatedUserFarms.push(updatedFarm);
            }

            return {
                ...state,
                user_farms_list: updatedUserFarms,
                loading: false
            };
        }

        case FarmActionTypes.UPDATE_FARM_ANIMALS_SUCCESS: {

            const updatedUserFarms = state.user_farms_list.filter((farm) => farm.id !== action.payload.farmId);

            const farm = state.user_farms_list.filter((farm) => farm.id === action.payload.farmId);
            farm[0].species = action.payload.species;

            updatedUserFarms.push(farm[0]);

            return {
                ...state,
                user_farms_list: updatedUserFarms,
                loading: false
            };
        }

        case FarmActionTypes.UPDATE_FARM_SUCCESS: {

            const updatedUserFarms = state.user_farms_list.filter((farm) => farm.id !== action.payload.farm.id);

            const farm = state.user_farms_list.filter((farm) => farm.id === action.payload.farm.id);
            farm[0] = action.payload.farm;

            updatedUserFarms.push(farm[0]);

            return {
                ...state,
                user_farms_list: updatedUserFarms,
                loading: false
            };
        }

        case FarmActionTypes.INVITE_USER_TO_FARMS_SUCCESS:
        case FarmActionTypes.INVITE_USER_TO_FARMS_FAILED:
        case FarmActionTypes.UPDATE_FARM_FAILED:
        case FarmActionTypes.UPDATE_FARM_ANIMALS_FAILED:
        case FarmActionTypes.ADD_USER_TO_FARMS_FAILED: {
            return {
                ...state,
                loading: false
            };
        }

        case FarmActionTypes.ADD_CARD_TO_FARM_SUCCESS: {

            const farms = state.user_farms_list;
            for (let farm of farms) {
                if (farm.id === action.payload.farmId) {
                    farm.paymentMethods.push(action.payload.paymentMethod);
                }

            }
            return {
                ...state,
                user_farms_list: farms,
                loading: false
            }
        }

        case FarmActionTypes.REMOVE_CARD_FROM_FARM: {
            return {
                ...state,
                loading: true,
                card_removed: false
            };
        }

        case FarmActionTypes.REMOVE_CARD_FROM_FARM_SUCCESS: {
            return {
                ...state,
                loading: true,
                card_removed: true
            };
        }

        case CountActionTypes.RESEND_EMAIL_RESULTS: {
            return {
                ...state,
                loading: true
            };
        }

        case CountActionTypes.RESEND_EMAIL_RESULTS_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }

        case FarmActionTypes.ADD_CARD_TO_FARM_FAILED:
        case CountActionTypes.RESEND_EMAIL_RESULTS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        }


        case FarmActionTypes.SELECT_FARM: {
            const selectedFarm = state.all_farms_list.filter(farm => farm.id === action.payload.farm_id);
            return {
                ...state,
                existing_farm: selectedFarm[0]
            };
        }

        case FarmActionTypes.SELECT_USER_FARM: {
            const selectedFarm = state.user_farms_list.filter(farm => farm.id === action.payload.farm_id);
            return {
                ...state,
                existing_farm: selectedFarm[0]
            };
        }

        case FarmActionTypes.SELECT_USER_FARM_ROLE: {
            const selectedFarm = state.existing_farm;
            let userRole = RoleType.Viewer;
            for (const user of selectedFarm.users) {
                if (user.id === state.current_user.id) {
                    userRole = user.role;
                }
            }
            return {
                ...state,
                user_farm_role: userRole
            };
        }

        case AnimalsActionTypes.LOAD_ANIMALS: {
            return {
                ...state,
                loading: true
            };
        }

        case AnimalsActionTypes.LOAD_ANIMALS_SUCCESS: {
            return {
                ...state,
                animals_list: action.payload.animals,
                error: null
            };
        }

        case AnimalsActionTypes.LOAD_ANIMALS_FAILED: {
            return {
                ...state,
                animals_list: [],
                error: action.payload.error
            };
        }

        case CountActionTypes.COMPLETE_COUNT:
            return {
                ...state,
                loading: true
            };

        case CountActionTypes.COMPLETE_COUNT_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case CountActionTypes.COMPLETE_COUNT_FAILED:
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };

    }
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [] : [];

const currentAppState = (state) => state.app;

export const selectError = createSelector(
    currentAppState,
    (state: AppState) => state ? state.error : null
);

export const selectUserDeviceId = createSelector(
    currentAppState,
    (state: AppState) => state.user_device_id
);

export const selectCurrentUser = createSelector(
    currentAppState,
    (state: AppState) => state ? state.current_user : null
);

export const selectAllUserFarms = createSelector(
    currentAppState,
    (state: AppState) => state.all_farms_list
);

export const selectCurrentUserFarms = createSelector(
    currentAppState,
    (state: AppState) => state ? state.user_farms_list : null
);

export const selectCurrentUserCounts = createSelector(
    currentAppState,
    (state: AppState) => state.counts_list
);

export const selectNewUserFarm = createSelector(
    currentAppState,
    (state: AppState) => state.new_farm
);

export const selectNewFarmSpecies = createSelector(
    currentAppState,
    (state: AppState) => state.new_farm.species
);

export const selectExistingUserFarm = createSelector(
    currentAppState,
    (state: AppState) => state.existing_farm
);

export const selectExistingCount = createSelector(
    currentAppState,
    (state: AppState) => state.existing_count
);

export const selecUserFarmRole = createSelector(
    currentAppState,
    (state: AppState) => state.user_farm_role
);

export const selectCurrentCount = createSelector(
    currentAppState,
    (state: AppState) => state.current_count
);

export const selectCurrentCountForm = createSelector(
    currentAppState,
    (state: AppState) => state.current_count.animal_count_form
);

export const selectAllUsers = createSelector(
    currentAppState,
    (state: AppState) => state.all_users_list
);

export const selectPricingScales = createSelector(
    currentAppState,
    (state: AppState) => state.pricing_scales
);

export const selectAreaAnimals = createSelector(
    currentAppState,
    (state: AppState) => state.animals_list
);

export const selectCurrentState = createSelector(
    currentAppState,
    (state: AppState) => state
);
