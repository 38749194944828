import { Action } from '@ngrx/store';
import { AppState } from '../../models';

export * from './user.actions';
export * from './animals.actions';
export * from './count.actions';
export * from './farm.actions';

export enum MainActionTypes {
    CLEAR_ERROR = '[Auth Pages] Clear error',
    LOAD_ALL_USER_REQUIRED_DETAILS = '[Home Page] Load All User Required Details',
    LOAD_ALL_USER_REQUIRED_DETAILS_SUCCESS = '[Home Page] Load All User Required Details Success',
    LOAD_ALL_USER_REQUIRED_DETAILS_FAILED = '[Home Page] Load All User Required Details Failed',
    APP_STAT_USERS = '[Main Page] Load Users On App',
    APP_STAT_OTHER = '[Main Page] Load Other App Stats'
}

export class MainAction implements Action {
    type: string;
    payload: {
        requiredInfo: AppState;
    };
}

export class CLEAR_ERROR implements Action {
    readonly type = MainActionTypes.CLEAR_ERROR;
}

export class LOAD_ALL_USER_REQUIRED_DETAILS implements Action {
    readonly type = MainActionTypes.LOAD_ALL_USER_REQUIRED_DETAILS;
}

export class LOAD_ALL_USER_REQUIRED_DETAILS_SUCCESS implements Action {
    readonly type = MainActionTypes.LOAD_ALL_USER_REQUIRED_DETAILS_SUCCESS;

    constructor(public payload: { requiredInfo: AppState }) {
    }
}

export class LOAD_ALL_USER_REQUIRED_DETAILS_FAILED implements Action {
    readonly type = MainActionTypes.LOAD_ALL_USER_REQUIRED_DETAILS_FAILED;

    constructor(readonly payload: { error: string }) {
    }
}

export class APP_STAT_USERS implements Action {
    readonly type = MainActionTypes.APP_STAT_USERS;

    constructor(public payload: { users: number }) {
    }
}

export class APP_STAT_OTHER implements Action {
    readonly type = MainActionTypes.APP_STAT_OTHER;

    constructor(public payload: { stats: { farms: number, counts: number, hectare: number } }) {
    }
}


export type UserActionsUnion = CLEAR_ERROR | LOAD_ALL_USER_REQUIRED_DETAILS | LOAD_ALL_USER_REQUIRED_DETAILS_SUCCESS
| LOAD_ALL_USER_REQUIRED_DETAILS_FAILED | APP_STAT_USERS | APP_STAT_OTHER;