import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EmailValidator } from '../../../shared/validators/email.validator';
import { Store, select } from '@ngrx/store';
import { AppState, SignupUserInfo } from 'src/app/shared/models';
import { REGISTER_USER } from 'src/app/shared/state/actions/user.actions';
import { Observable, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { selectCurrentState } from 'src/app/shared/state/reducers';
import { CLEAR_ERROR } from 'src/app/shared/state/actions/main-actions.actions';
import { EqualValueValidator } from 'src/app/shared/validators/equal-value.validator';

@Component({
    selector: 'app-gamesurv-register',
    templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit, OnDestroy {

    currentState$: Observable<AppState> = this.store.pipe(delay(0), select(selectCurrentState));
    private stateSubscription: Subscription;

    public registerForm;

    busyRegistering = false;

    error = '';
    showError = false;

    constructor(
        public formBuilder: FormBuilder,
        private store: Store<AppState>,
    ) {
        this.busyRegistering = false;
        this.showError = false;
        this.registerForm = formBuilder.group({
            name: ['', Validators.compose([Validators.required])],
            surname: ['', Validators.compose([Validators.required])],
            email: ['', Validators.compose([Validators.required, EmailValidator.isValid])],
            password: ['', Validators.compose([Validators.minLength(6), Validators.required])],
            confirm_password: ['', Validators.compose([Validators.minLength(6), Validators.required])]
        }, {
            validator: EqualValueValidator('password', 'confirm_password')
        });
    }

    ngOnInit() {
        this.stateSubscription = this.currentState$.subscribe((appState: AppState) => {
            if (appState && appState.error) {
                this.busyRegistering = false;
                this.showError = true;
                this.error = appState.error;
            }
        });
    }

    ngOnDestroy() {
        this.store.dispatch(new CLEAR_ERROR());
        this.stateSubscription.unsubscribe();
    }

    get f(){
        return this.registerForm.controls;
      }

    registerUser(): void {
        this.showError = false;
        this.busyRegistering = true;
        if (!this.registerForm.valid) {
            this.busyRegistering = false;
        } else {
            const user: SignupUserInfo = {
                email: this.registerForm.controls.email.value,
                surname: this.registerForm.controls.surname.value,
                name: this.registerForm.controls.name.value,
                password: this.registerForm.controls.password.value
            };
            this.store.dispatch(new REGISTER_USER({ userInfo: user }));
        }
    }

}
