import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html'
  // styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {

  constructor(@Inject(DOCUMENT) private _document) { }

  ngOnInit() {
    // switch background styles
    this._document.body.classList.remove('game-surv');
    this._document.body.classList.add('landing');
  }

}
