
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Observable, Subject } from 'rxjs';
import { Credentials } from '../models/credentials.model';
import { SignupUserInfo, User, UserFarm, Animal, Count, LinkedUser, CountDetail, MethodOfPayment, MapLocation, Payment, AppState } from '../models';
import { RoleType } from '../models/enums/RoleType';
import { BillingScale } from '../models/billing_scale.model';
import { DocumentReference, QuerySnapshot, DocumentData, QueryDocumentSnapshot } from '@angular/fire/firestore/interfaces';
import { LOAD_USER } from '../state/actions/user.actions';
import { LOAD_ANIMALS } from '../state/actions/animals.actions';
import { Store } from '@ngrx/store';
import { LinkedUserCount } from '../models/linked-user-count.model';

@Injectable()
export class FirebaseProvider {
    private user: Observable<firebase.User> = null;
    public loggedIn = new Subject<boolean>();
    private loggedInUser: User = null;
    private userDetails: firebase.User = null;

    constructor(private _firebaseAuth: AngularFireAuth, private _firebaseStore: AngularFirestore, private router: Router, private store: Store<AppState>) {

        // this.loadUserRegistered().then((users) => this.store.dispatch(new APP_STAT_USERS({ users: users })));
        // this.loadAppStats().then((stats) => this.store.dispatch(new APP_STAT_OTHER({ stats: stats })));
        // this.user = _firebaseAuth.authState;

        // this.user.subscribe(
        //     (user) => {
        //         if (user) {
        //             this.userDetails = user;
        //             localStorage.setItem('user', JSON.stringify(user));
        //             this.loggedIn.next(true);
        //             JSON.parse(localStorage.getItem('user'));
        //             this.store.dispatch(new LOAD_USER());
        //             this.store.dispatch(new LOAD_ANIMALS());
        //         }
        //         else {
        //             this.userDetails = null;
        //             localStorage.setItem('user', null);
        //             this.loggedIn.next(false);
        //             JSON.parse(localStorage.getItem('user'));
        //         }
        //     }
        // );

    }

    // loadAppStats() {

    //     let appStats: { farms: number, counts: number, hectare: number } = { farms: 0, counts: 0, hectare: 0 };
    //     let allCounts: { id: string, detail: CountDetail[] }[] = [];

    //     return this._firebaseStore
    //         .collection('farms').ref
    //         .get()
    //         .then((farmsSnapshot: QuerySnapshot<DocumentData>) => {
    //             let farmList: Array<string> = new Array<string>();
    //             let hectareTotal = 0;
    //             // farmList = Object.assign([], farmList);
    //             farmsSnapshot.docs.forEach(async (farmSnapshot: QueryDocumentSnapshot<DocumentData>) => {

    //                 farmList.push(farmSnapshot.id);
    //                 hectareTotal = hectareTotal + farmSnapshot.data().total_farm_size;

    //             });
    //             appStats.farms = farmList.length;
    //             appStats.hectare = hectareTotal;
    //             return farmList;
    //         }).then((farms) => {
    //             let countPromises = [];

    //             farms.forEach((farm) => {

    //                 const countPromise = this._firebaseStore.collection('farms').doc(farm).collection('counts').ref.get().then((counts: QuerySnapshot<DocumentData>) => {
    //                     let farmCounts: { id: string, detail: CountDetail[] }[] = [];
    //                     counts.docs.forEach(async (count: QueryDocumentSnapshot<DocumentData>) => {
    //                         let details: Array<CountDetail> = new Array<CountDetail>();

    //                         const countVal: { id: string, detail: CountDetail[] } = {
    //                             id: count.id,
    //                             detail: details
    //                         };
    //                         allCounts.push(countVal);
    //                     });
    //                 });

    //                 countPromises.push(countPromise);
    //             });

    //             return Promise.all([...countPromises]).then((val) => {
    //                 appStats.counts = allCounts.length;
    //                 return appStats;
    //             });
    //         });
    // }

    // loadUserRegistered() {
    //     return this._firebaseStore
    //         .collection('users')
    //         .ref.get()
    //         .then((usersVal) => usersVal.docs.length);
    // }

    isLoggedIn() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user == null) {
            return false;
        } else {
            return true;
        }
    }

    // authenticate(creds: Credentials): Promise<any> {
    //     return this._firebaseAuth.setPersistence('local').then(() => this._firebaseAuth.signInWithEmailAndPassword(creds.username, creds.password).then((val) => {
    //         this.userDetails = val.user;
    //         return val.user;
    //     }).catch((error) => {
    //         return { error };
    //     })).catch((error: any) => {
    //         return { error };
    //     });
    // }

    // logout() {
    //     this.user = null;
    //     return this._firebaseAuth
    //         .signOut().then(() => {
    //             localStorage.removeItem('user');
    //             this.router.navigate(['/']);
    //         })
    //         .catch((error) => {
    //             return { error };
    //         });
    // }

    /**
     * [resetPassword description]
     * This function will take the user's email address and send a password reset link, then Firebase will handle the
     * email reset part, you won't have to do anything else.
     *
     * @param  {string} email    [User's email address]
     */
    // resetPassword(email: string): Promise<any> {
    //     return this._firebaseAuth.sendPasswordResetEmail(email).then(() => true).catch((error) => {
    //         return { error };
    //     });;
    // }

    // createUser(signupUserInfo: SignupUserInfo): Promise<any> {
    //     return this._firebaseAuth

    //         .createUserWithEmailAndPassword(signupUserInfo.email, signupUserInfo.password).then((user) => {
    //             if (user) {
    //                 this.userDetails = user.user;
    //                 // Check for farm linking by invite
    //                 this._firebaseStore.collection('user_invite').ref.get().then((links: QuerySnapshot<DocumentData>) => {
    //                     if (links && links.docs.length > 0) {
    //                         links.docs.forEach(link => {
    //                             if (link.data().email === signupUserInfo.email) {
    //                                 const newUser: User = {
    //                                     name: signupUserInfo.name,
    //                                     surname: signupUserInfo.surname,
    //                                     email: signupUserInfo.email,
    //                                     device_id: 'deviceId',
    //                                     id: user.user.uid
    //                                 };
    //                                 this.addUserToFarms(newUser, link.data().role, [link.data().farmId]);
    //                             }
    //                         });
    //                     }
    //                 });
    //                 this._firebaseStore.collection('users').doc(user.user.uid).set({
    //                     id: user.user.uid,
    //                     name: signupUserInfo.name,
    //                     surname: signupUserInfo.surname,
    //                     email: signupUserInfo.email,
    //                     device_id: 'deviceId'
    //                 }).then(docRef => {
    //                     return user.user.uid;
    //                 });
    //             }
    //         })
    //         .catch((error) => {
    //             return { error };
    //         });
    // }

    // getUserDetails(): Promise<User> {
    //     const userId = this.userDetails.uid;
    //     return this._firebaseStore.collection('users').doc(userId).ref.get().then((snapshot) => {
    //         const loggedInUser: User = {
    //             id: snapshot.data().id,
    //             name: snapshot.data().name,
    //             surname: snapshot.data().surname,
    //             email: snapshot.data().email,
    //             device_id: snapshot.data().device_id
    //         };
    //         this.loggedInUser = loggedInUser;
    //         return Promise.resolve(loggedInUser);
    //     });
    // }

    //   getAllUsers(): Promise<User[]> {
    //     const usersList = [];
    //     return this._firebaseStore.collection('users').ref.get().then((snapshot) => {
    //       snapshot.forEach(element => {
    //         const user: User = {
    //           id: element.data().id,
    //           name: element.data().name,
    //           surname: element.data().surname,
    //           email: element.data().email,
    //           device_id: element.data().device_id
    //         };
    //         usersList.push(user);
    //       });
    //       return Promise.resolve(usersList);
    //     });
    //   }

    // updateUser(user: User) {
    //     this._firebaseStore.collection('users').doc(user.id).set({
    //         id: user.id,
    //         name: user.name,
    //         surname: user.surname,
    //         email: user.email,
    //         device_id: ''
    //     });
    // }

    // saveUserFarmDetails(userFarm: UserFarm) {
    //     return this._firebaseStore.collection('farms').add({
    //         name: userFarm.name,
    //         email: userFarm.email,
    //         createdDate: userFarm.createdDate,
    //         species: userFarm.species,
    //         layout: userFarm.layout,
    //         middle_point: userFarm.middlePoint,
    //         users: userFarm.users,
    //         totalFarmSize: userFarm.totalFarmSize,
    //         is_valid: true
    //     }).then(docRef => {
    //         return docRef.id;
    //     });
    // }

    // getAllAnimals(): Promise<Animal[]> {
    //     const animals = [];
    //     return this._firebaseStore.collection('animals').ref.get().then(snapshot => {
    //         snapshot.forEach(doc => {
    //             const animal: Animal = {
    //                 name: doc.data().name,
    //                 id: doc.id,
    //                 image: 'assets/animals/' + doc.data().image,
    //                 color: doc.data().color
    //             }
    //             animals.push(animal);
    //         })
    //         return animals;
    //     })
    // }

    //   getInProgressCountsForFarms(farmIds: string[]): Promise<CurrentCountDisplay[]> {
    //     const inProgressCounts: CurrentCountDisplay[] = [];
    //     const DocumentRefs = [];
    //     // Get existing count for farm
    //     for (const farmId of farmIds) {
    //       DocumentRefs.push(this._firebaseStore.collection('new_count').doc(farmId).ref.get());
    //     }
    //     return Promise.all(DocumentRefs)
    //       .then(result => {
    //         for (const snapshot of result) {
    //           if (snapshot.data()) {
    //             const count: CurrentCountDisplay = {
    //               farm_id: snapshot.data().farm_id,
    //               farm_email: snapshot.data().farm_email,
    //               farm_name: snapshot.data().farm_name,
    //               current_count: snapshot.data().current_count,
    //               completed_date: snapshot.data().completed_date,
    //               started_date: snapshot.data().started_date,
    //               count_details: snapshot.data().count_details,
    //               animal_count_form: snapshot.data().animal_count_form
    //             }
    //             inProgressCounts.push(count);
    //           }
    //         }
    //         return inProgressCounts;
    //       });
    //   }

    //   getUnpaidCountsForFarms(farmIds: string[]): Promise<Count[]> {
    //     const unpaidCounts: Count[] = [];
    //     const UnpaidDocumentRefs = [];
    //     // Go through farms ids and find count that is unpaid
    //     for (const farmId of farmIds) {
    //       UnpaidDocumentRefs.push(this._firebaseStore.collection('counts', ref => ref.where('farm_id', '==', farmId)).get().toPromise());
    //     }
    //     return Promise.all(UnpaidDocumentRefs)
    //       .then(resultUnpaid => {
    //         for (const snapshotUnpaid of resultUnpaid) {
    //           for (const snapshotUnpaidResult of snapshotUnpaid.docs) {
    //             if (snapshotUnpaidResult.data() && (snapshotUnpaidResult.data().paid === undefined || !snapshotUnpaidResult.data().paid)) {
    //               const count: Count = {
    //                   id: sna
    //                 farmId: snapshotUnpaidResult.data().farm_id,
    //                 farmEmail: snapshotUnpaidResult.data().farm_email,
    //                 farmName: snapshotUnpaidResult.data().farm_name,
    //                 completedDate: snapshotUnpaidResult.data().completed_date.toDate(),
    //                 statDate: snapshotUnpaidResult.data().started_date.toDate(),
    //                 countDetails: snapshotUnpaidResult.data().count_details,
    //                 paid: snapshotUnpaidResult.data().paid,
    //                 // count: snapshotUnpaidResult.id,
    //                 paidDate: snapshotUnpaidResult.data().date_paid,
    //                 orderId: snapshotUnpaidResult.data().order_id
    //               }
    //               unpaidCounts.push(count);
    //             }
    //           }
    //         }
    //         return Promise.resolve(unpaidCounts);
    //       });
    //   }

    // checkInProgressCountFarm(farmId: string): Promise<CurrentCountDisplay> {
    //     let count: CurrentCountDisplay = {
    //         farm_id: null,
    //         farm_email: null,
    //         farm_name: null,
    //         current_count: null,
    //         completed_date: null,
    //         started_date: null,
    //         count_details: null,
    //         animal_count_form: null,
    //     }

    //     // Get existing count for farm
    //     return this._firebaseStore.collection('new_count').doc(farmId).ref.get().then((snapshot) => {
    //         if (snapshot.data()) {
    //             count = {
    //                 farm_id: snapshot.data().farm_id,
    //                 farm_email: snapshot.data().farm_email,
    //                 farm_name: snapshot.data().farm_name,
    //                 current_count: snapshot.data().current_count,
    //                 completed_date: snapshot.data().completed_date,
    //                 started_date: snapshot.data().started_date,
    //                 count_details: snapshot.data().count_details,
    //                 animal_count_form: snapshot.data().animal_count_form
    //             }
    //             return count;
    //         }
    //     }).catch(() => count);
    // }

    // getUserCountForFarm(farmIds: string[]): Promise<Count[]> {
    //     const countsForFarm: Count[] = [];
    //     // Get count for farm
    //     return this._firebaseStore.collection('counts').ref.get().then(snapshot => {

    //         snapshot.forEach(doc => {
    //             for (const farmId of farmIds) {
    //                 if (doc.data().farm_id === farmId) {
    //                     const count: Count = {
    //                         farm_id: doc.data().farm_id,
    //                         farm_name: doc.data().farm_name,
    //                         farm_email: doc.data().farm_email,
    //                         count_id: doc.id,
    //                         completed_date: doc.data().completed_date.toDate(),
    //                         started_date: doc.data().started_date.toDate(),
    //                         count_details: doc.data().count_details,
    //                         paid: doc.data().paid,
    //                         date_paid: doc.data().date_paid,
    //                         order_id: doc.data().order_id
    //                     }
    //                     countsForFarm.push(count);
    //                 }

    //             }
    //         });
    //         return Promise.resolve(countsForFarm);
    //     });
    // }

    // getUserFarmDetails(): Promise<UserFarm[]> {
    //     const list = [];
    //     return this._firebaseStore.collection('farms').ref.get().then(snapshot => {

    //         snapshot.docs.forEach(doc => {
    //             const users: User[] = doc.data().users;
    //             if (users) {
    //                 for (const user of users) {
    //                     if (user.id === this.userDetails.uid) {
    //                         // Farm cast
    //                         const userFarm: UserFarm = {
    //                             name: doc.data().name,
    //                             email: doc.data().email,
    //                             created_date: doc.data().created_date,
    //                             id: doc.id,
    //                             users: doc.data().users,
    //                             species: doc.data().species,
    //                             layout: doc.data().layout,
    //                             middle_point: doc.data().middle_point,
    //                             total_farm_size: doc.data().total_farm_size,
    //                             customerId: '',
    //                             override_price: doc.data().override_price
    //                         }
    //                         list.push(userFarm);
    //                     }
    //                 }
    //             }
    //         });
    //         return Promise.resolve(list);
    //     });
    // }

    // getAllFarms(): Promise<UserFarm[]> {
    //     const list = [];
    //     return this._firebaseStore.collection('farms').ref.get().then(snapshot => {
    //         snapshot.docs.forEach(doc => {
    //             // Farm cast
    //             const userFarm: UserFarm = {
    //                 name: doc.data().name,
    //                 email: doc.data().email,
    //                 created_date: doc.data().created_date,
    //                 id: doc.id,
    //                 users: doc.data().users,
    //                 species: doc.data().species,
    //                 layout: doc.data().layout,
    //                 middle_point: doc.data().middle_point,
    //                 total_farm_size: doc.data().total_farm_size,
    //                 customerId: '',
    //                 override_price: doc.data().override_price
    //             }
    //             list.push(userFarm);
    //         });
    //         return Promise.resolve(list);
    //     });
    // }



    // startNewCount(newCount: CurrentCount) {
    //     return this._firebaseStore.collection('new_count').doc(newCount.farm_id).set({
    //         current_count: newCount.current_count,
    //         animal_count_form: newCount.animal_count_form,
    //         count_details: newCount.count_details,
    //         farm_id: newCount.farm_id,
    //         farm_name: newCount.farm_name,
    //         farm_email: newCount.farm_email,
    //         completed_date: newCount.completed_date,
    //         started_date: newCount.started_date
    //     }).then(pass => {
    //         return newCount;
    //     });
    // }

    // addCountDetails(countDetail: CountDetail) {

    //     var currentCountRef = this._firebaseStore.collection('new_count').doc(countDetail.farm_id);

    //     return currentCountRef.update({
    //         count_details: firebase.firestore.FieldValue.arrayUnion(countDetail)
    //     }).then(() => Promise.resolve(countDetail));
    // }

    // deleteCurrentCount(farmId: string) {

    //     return this._firebaseStore.collection('new_count').ref.get().then(snapshot => {

    //         snapshot.forEach(doc => {
    //             if (doc.id === farmId) {
    //                 doc.ref.delete();
    //             }
    //         });
    //         return true;
    //     });
    // }

    // finishCount(currentCount: Count) {
    //     // Clear count_details and new_count collection for farm
    //     this.deleteCurrentCount(currentCount.farm_id);
    //     return this._firebaseStore.collection('counts').add({
    //         farm_id: currentCount.farm_id,
    //         farm_name: currentCount.farm_name,
    //         farm_email: currentCount.farm_email,
    //         completed_date: currentCount.completed_date,
    //         started_date: currentCount.started_date,
    //         count_details: currentCount.count_details,
    //         paid: currentCount.paid,
    //         date_paid: currentCount.date_paid
    //     }).then(pass => {
    //         currentCount.count_id = pass.id;
    //         return currentCount;
    //     });
    // }

    // addCountDetailsToFinishedCount(countDetail: CountDetail): Promise<Count> {
    //     let count: Count = {
    //         farm_id: null,
    //         farm_name: null,
    //         farm_email: null,
    //         count_id: null,
    //         completed_date: null,
    //         started_date: null,
    //         count_details: null,
    //         paid: false,
    //         date_paid: null,
    //         order_id: null
    //     };
    //     return this._firebaseStore.collection('counts').ref.get().then(snapshot => {
    //         snapshot.forEach(doc => {
    //             if (doc.data().farm_id === countDetail.farm_id) {
    //                 if (countDetail.date > doc.data().started_date.toDate() && countDetail.date < doc.data().completed_date.toDate()) {

    //                     let finishedCountDetails = doc.data().count_details;
    //                     finishedCountDetails.push(countDetail);

    //                     count = {
    //                         farm_id: doc.data().farm_id,
    //                         farm_name: doc.data().farm_name,
    //                         farm_email: doc.data().farm_email,
    //                         count_id: doc.data().count_id,
    //                         completed_date: doc.data().completed_date.toDate(),
    //                         started_date: doc.data().started_date.toDate(),
    //                         count_details: finishedCountDetails,
    //                         paid: doc.data().paid,
    //                         date_paid: doc.data().date_paid,
    //                         order_id: doc.data().order_id
    //                     };

    //                     this.db.collection('counts').doc(doc.id).set({
    //                         count: count
    //                     });
    //                 }
    //             }
    //         });

    //         return count;
    //     });
    // }

    // loadPricingScales(): Promise<BillingScale[]> {
    //     const billingScales: BillingScale[] = [];
    //     return this._firebaseStore.collection('billing_scale').ref.get().then(snapshot => {
    //         snapshot.forEach(doc => {
    //             const scale: BillingScale = {
    //                 scale_end: doc.data().scale_end,
    //                 scale_start: doc.data().scale_start,
    //                 scale_fee: doc.data().scale_fee
    //             }
    //             billingScales.push(scale);
    //         });
    //         return Promise.resolve(billingScales);
    //     });
    // }

    // async createFarm(farm: UserFarm): Promise<{ farm: UserFarm, id: string }> {
    //     let users = [];
    //     users.push({
    //         id: this.loggedInUser.id,
    //         name: this.loggedInUser.name,
    //         surname: this.loggedInUser.surname,
    //         role: RoleType.Admin
    //     });

    //     let newFarm: UserFarm = {
    //         name: farm.name,
    //         email: farm.email,
    //         createdDate: farm.createdDate,
    //         users: users,
    //         totalFarmSize: farm.totalFarmSize,
    //         species: farm.species,
    //         layout: farm.layout,
    //         middlePoint: farm.middlePoint,
    //         customerId: farm.customerId,
    //         overridePrice: farm.overridePrice,
    //         farmCounts: farm.farmCounts,
    //         paymentMethods: farm.paymentMethods,
    //         cardAdded: farm.cardAdded,
    //         paymentMethodIds: farm.paymentMethodIds
    //     };


    //     const dr = await this._firebaseStore.collection('farms').add({
    //         name: newFarm.name,
    //         email: newFarm.email,
    //         users: users,
    //         layout: this.convertLayoutToJson(newFarm.layout),
    //         middle_point: {
    //             latitude: newFarm.middlePoint.lat,
    //             longitude: newFarm.middlePoint.lng
    //         },
    //         total_farm_size: newFarm.totalFarmSize,
    //         species: newFarm.species,
    //         created_date: newFarm.createdDate,
    //         override_price: newFarm.overridePrice,
    //         customerId: newFarm.customerId
    //     });
    //     return { farm: newFarm, id: dr.id };

    // }

    // convertLayoutToJson(layout) {
    //     const layoutList = [];
    //     layout.forEach((layout: MapLocation) => {
    //         layoutList.push({
    //             latitude: layout.lat,
    //             longitude: layout.lng
    //         });
    //     });
    //     return layoutList;
    // }

    // addCardToFarm(
    //     farmId: string, methodOfPayment: MethodOfPayment): Promise<String> {
    //     return this._firebaseStore
    //         .collection('farms')
    //         .doc(farmId)
    //         .collection('cards')
    //         .add({
    //             paymentMethodId: methodOfPayment.paymentMethodId,
    //             customerId: methodOfPayment.customerId
    //         }).then((dr: DocumentReference) => dr.id);
    // }

    // addUserToFarms(user: User, role: RoleType, farmIds: string[]) {

    //     const userLink = {
    //         id: user.id,
    //         name: user.name,
    //         surname: user.surname,
    //         role: role
    //     };

    //     return this._firebaseStore
    //         .collection('linking').add({
    //             'user': userLink, 'farms': farmIds
    //         }).then(() => {
    //             return true;
    //         });
    // }

    // removeUserFromFarm(users: LinkedUser[], farmId: string) {
    //     return this._firebaseStore.collection('farms').doc(farmId).update({
    //         users: users
    //     }).then(pass => {
    //         return true;
    //     });
    // }

    // updateFarm(farm: UserFarm) {

    //     return this._firebaseStore.collection('farms').doc(farm.id.toString()).update({
    //         name: farm.name,
    //         email: farm.email,
    //         users: farm.users,
    //         total_farm_size: farm.totalFarmSize,
    //         species: farm.species,
    //         layout: this.convertLayoutToJson(farm.layout),
    //         middle_point: {
    //             latitude: farm.middlePoint.lat,
    //             longitude: farm.middlePoint.lng
    //         },
    //         paymentMethods: farm.paymentMethods
    //     }).then(pass => {
    //         return farm;
    //     });
    // }

    // inviteUserToFarms(email: string, role: RoleType, farmsIds: string[]) {
    //     return this._firebaseStore
    //         .collection('invites').add({
    //             'email': email,
    //             'role': role,
    //             'farms': farmsIds,
    //             'inviteeId': this.loggedInUser.id
    //         }).then((values) => {
    //             console.log(values);
    //             return true;
    //         });
    // }

    // getUserFarms(): Promise<UserFarm[]> {
    //     const users = [];
    //     if (this.loggedInUser != null && this.loggedInUser.id != null) {
    //         users.push({
    //             role: RoleType.Admin,
    //             surname: this.loggedInUser.surname,
    //             name: this.loggedInUser.name,
    //             id: this.loggedInUser.id

    //         });
    //         users.push({
    //             role: RoleType.Colleague,
    //             surname: this.loggedInUser.surname,
    //             name: this.loggedInUser.name,
    //             id: this.loggedInUser.id
    //         });
    //         users.push({
    //             role: RoleType.Viewer,
    //             surname: this.loggedInUser.surname,
    //             name: this.loggedInUser.name,
    //             id: this.loggedInUser.id
    //         });
    //     } else {
    //         users.push({
    //             role: RoleType.Viewer,
    //             surname: 'Initial',
    //             name: 'None',
    //             id: 1
    //         });
    //     }


    //     let farmCounts: Count[] = new Array<Count>();
    //     let paymentMethods: MethodOfPayment[] = [];

    //     return this._firebaseStore
    //         .collection('farms').ref
    //         .get()
    //         .then((farmsSnapshot: QuerySnapshot<DocumentData>) => {
    //             let farmList: Array<UserFarm> = new Array<UserFarm>();
    //             // farmList = Object.assign([], farmList);
    //             farmsSnapshot.docs.forEach(async (farmSnapshot: QueryDocumentSnapshot<DocumentData>) => {
    //                 let users = [];

    //                 farmSnapshot.data().users.forEach((user) => {
    //                     const userVal = {
    //                         id: user.id,
    //                         name: user.name,
    //                         surname: user.surname,
    //                         role: user.role,
    //                         selectedRoles: [{ "id": user.role == 'Administrator' ? 1 : user.role == 'Colleague' ? 2 : 3, "itemName": user.role }]
    //                     };
    //                     users.push(userVal);
    //                 });
    //                 const species: number[] = farmSnapshot.data().species;

    //                 const layout: MapLocation[] = [];
    //                 farmSnapshot.data().layout.forEach((point) => {
    //                     const maplocation: MapLocation = {
    //                         lat: point.latitude,
    //                         lng: point.longitude
    //                     }
    //                     layout.push(maplocation);
    //                 });

    //                 const middle = farmSnapshot.data().middle_point;
    //                 const middlePoint: MapLocation = {
    //                     lat: middle.latitude,
    //                     lng: middle.longitude
    //                 };

    //                 const farm: UserFarm = {
    //                     id: farmSnapshot.id,
    //                     name: farmSnapshot.data().name,
    //                     email: farmSnapshot.data().email,
    //                     createdDate: farmSnapshot.data().created_date,
    //                     users: users,
    //                     totalFarmSize: farmSnapshot.data().total_farm_size,
    //                     species: species,
    //                     layout: layout,
    //                     middlePoint: middlePoint,
    //                     overridePrice: farmSnapshot.data().overridePrice,
    //                     farmCounts: farmCounts,
    //                     paymentMethods: paymentMethods,
    //                     customerId: farmSnapshot.data().customerId
    //                 };
    //                 if (users) {
    //                     for (const user of users) {
    //                         if (user.id === this.userDetails.uid) {
    //                             farmList.push(farm);
    //                         }
    //                     }
    //                 }
    //             });

    //             return farmList;
    //         }).then((farms) => {
    //             let countPromises = [];
    //             farms.forEach((farm) => {
    //                 const countPromise = this._firebaseStore.collection('farms').doc(farm.id).collection('counts').ref.get().then((counts: QuerySnapshot<DocumentData>) => {
    //                     farmCounts = [];
    //                     const payment = new Array<Payment>();
    //                     counts.docs.forEach(async (count: QueryDocumentSnapshot<DocumentData>) => {
    //                         let details: Array<CountDetail> = new Array<CountDetail>();

    //                         const countVal: Count = {
    //                             id: count.id,
    //                             userCreated: count.data().userCreated,
    //                             startDate: count.data().startDate.toDate(),
    //                             completedDate: count.data().completedDate ? count.data().completedDate.toDate() : null,
    //                             farmId: count.data().farmId,
    //                             farmName: count.data().farmName,
    //                             paid: count.data().paid,
    //                             paidDate: count.data().paidDate ? count.data().paidDate.toDate() : null,
    //                             orderId: count.data().orderId,
    //                             countDetails: details,
    //                             emailed: count.data().emailed,
    //                             payment: payment ? payment[0] : null,
    //                             linkedUserCounts: []
    //                         };
    //                         farmCounts.push(countVal);
    //                     });
    //                     farmCounts.sort((a, b) => Math.abs(new Date(b.startDate).getTime() - new Date(a.startDate).getTime()));
    //                     farm.farmCounts = farmCounts;
    //                 });

    //                 countPromises.push(countPromise);
    //             });

    //             return Promise.all([...countPromises]).then(() => farms);
    //         }).then((farms) => {
    //             let countDetailPromises = [];
    //             farms.forEach(farm => {
    //                 farm.farmCounts.forEach(count => {
    //                     const countDetailPromise = this._firebaseStore.collection('farms').doc(farm.id).collection('counts').doc(count.id).collection('details').ref.orderBy('animalName').get().then((detailsList: QuerySnapshot<DocumentData>) => {
    //                         let details: Array<CountDetail> = new Array<CountDetail>();
    //                         details = [];

    //                         detailsList.docs.forEach((detail: QueryDocumentSnapshot<DocumentData>) => {
    //                             const location: MapLocation = {
    //                                 lat: detail.data().location.latitude,
    //                                 lng: detail.data().location.longitude
    //                             };

    //                             const countDetail: CountDetail = {
    //                                 id: detail.id,
    //                                 animalName: detail.data().animalName,
    //                                 animalId: detail.data().animalId,
    //                                 maleCount: detail.data().maleCount,
    //                                 femaleCount: detail.data().femaleCount,
    //                                 unknownCount: detail.data().unknownCount,
    //                                 maleAge: detail.data().maleAge,
    //                                 femaleAge: detail.data().femaleAge,
    //                                 unknownAge: detail.data().unknownAge,
    //                                 date: detail.data().date,
    //                                 user: detail.data().user,
    //                                 userName: detail.data().userName,
    //                                 location: location,
    //                                 countInFarm: detail.data().countInFarm
    //                             };
    //                             details.push(countDetail);
    //                         });
    //                         count.countDetails = details;
    //                     });
    //                     countDetailPromises.push(countDetailPromise);
    //                 });
    //             });

    //             return Promise.all([...countDetailPromises]).then(() => farms);
    //         }).then((farms) => {
    //             let countPaymentPromises = [];
    //             farms.forEach(farm => {
    //                 farm.farmCounts.forEach(count => {
    //                     const countPaymentPromise = this._firebaseStore.collection('farms').doc(farm.id).collection('counts').doc(count.id).collection('payment').ref.get().then((paymentDetails: QuerySnapshot<DocumentData>) => {
    //                         let payment: Payment[] = new Array<Payment>();

    //                         paymentDetails.docs.forEach((paymentDetail: QueryDocumentSnapshot<DocumentData>) => {
    //                             const paymentVal: Payment = {
    //                                 id: paymentDetail.id,
    //                                 error: paymentDetail.data().error,
    //                                 clientSecret: paymentDetail.data().clientSecret,
    //                                 paymentSetupIntentId: paymentDetail.data().paymentSetupIntentId,
    //                                 nextAction: paymentDetail.data().nextAction,
    //                                 paymentIntentId: paymentDetail.data().paymentIntentId
    //                             };
    //                             payment.push(paymentVal);
    //                         });
    //                         count.payment = payment[0];
    //                     });
    //                     countPaymentPromises.push(countPaymentPromise);
    //                 });

    //             });

    //             return Promise.all([...countPaymentPromises]).then(() => farms);

    //         }).then((farms) => {
    //             let LinkedUserPromises = [];
    //             farms.forEach(farm => {
    //                 farm.farmCounts.forEach(count => {
    //                     const linkedUserPromise = this._firebaseStore.collection('farms').doc(farm.id).collection('counts').doc(count.id).collection('linked_users').ref.get().then((linkedUserDetail: QuerySnapshot<DocumentData>) => {
    //                         let linkedUsers: LinkedUserCount[] = new Array<LinkedUserCount>();

    //                         linkedUserDetail.docs.forEach((linkedUser: QueryDocumentSnapshot<DocumentData>) => {
    //                             const linkedUserCount: LinkedUserCount = {
    //                                 added: linkedUser.data().added,
    //                                 id: linkedUser.id,
    //                                 name: linkedUser.data().name,
    //                                 surname: linkedUser.data().surname,
    //                                 role: linkedUser.data().role,
    //                                 completedCount: linkedUser.data().completedCount,
    //                                 completedDate: linkedUser.data().completedDate
    //                             };
    //                             linkedUsers.push(linkedUserCount);
    //                         });
    //                         count.linkedUserCounts = linkedUsers;
    //                     });
    //                     LinkedUserPromises.push(linkedUserPromise);
    //                 });

    //             });

    //             return Promise.all([...LinkedUserPromises]).then(() => farms);

    //         }).then((farms) => {
    //             let cardPromises = [];
    //             farms.forEach((farm: UserFarm) => {
    //                 const cardPromise = this._firebaseStore.collection('farms').doc(farm.id).collection('cards').ref.get().then((cards: QuerySnapshot<DocumentData>) => {
    //                     paymentMethods = [];
    //                     cards.docs.forEach((cardDetail) => {
    //                         const methodOfPayment: MethodOfPayment = {
    //                             paymentMethodId: cardDetail.data().paymentMethodId,
    //                             customerId: cardDetail.data().customerId
    //                         }
    //                         paymentMethods.push(methodOfPayment);
    //                     });
    //                     farm.paymentMethods = paymentMethods;
    //                 });
    //                 cardPromises.push(cardPromise);
    //             });
    //             return Promise.all([...cardPromises]).then(() => farms);
    //         });
    // }

    // completeCount(farmId: string, countId: string): Promise<string> {
    //     return this._firebaseStore
    //         .collection('farms')
    //         .doc(farmId)
    //         .collection('counts')
    //         .doc(countId).update({
    //             completedDate: Date.now()
    //         }).then(() => {
    //             return countId;
    //         });
    // }

    // paidForCount(farmId: string, countId: string): Promise<String> {
    //     return this._firebaseStore
    //         .collection('farms')
    //         .doc(farmId)
    //         .collection('counts')
    //         .doc(countId).update({
    //             paidDate: Date.now(),
    //             paid: true,
    //             emailed: false
    //         }).then(() => {
    //             return countId;
    //         });
    // }

    // resendResults(farmId: string, countId: string): Promise<String> {
    //     return this._firebaseStore
    //         .collection('farms')
    //         .doc(farmId)
    //         .collection('counts')
    //         .doc(countId).update({
    //             emailed: false
    //         }).then(() => {
    //             return countId;
    //         });
    // }

    // getAllUsers(): Promise<User[]> {
    //     return this._firebaseStore
    //         .collection('users')
    //         .ref.get()
    //         .then((usersVal) => {

    //             const users: User[] = [];
    //             usersVal.docs.forEach(async (userDetail: QueryDocumentSnapshot<DocumentData>) => {
    //                 const user: User = {
    //                     id: userDetail.id,
    //                     name: userDetail.data().name,
    //                     surname: userDetail.data().surname,
    //                     email: userDetail.data().email,
    //                     device_id: userDetail.data().deviceId
    //                 };
    //                 users.push(user);
    //             });
    //             return users;
    //         });
    // }

    // createPayment(amount: number, farmId: string, countId: string, description: string) {
    //     return this._firebaseStore
    //         .collection('farms')
    //         .doc(farmId)
    //         .collection('counts')
    //         .doc(countId)
    //         .collection('payment')
    //         .add({
    //             amount: amount,
    //             description: description,
    //             currency: 'zar'
    //         });
    // }

}