<div *ngIf="showCookie">
    <div class="cookie-popup rounded text-white w-100 mw-100 wow fadeInUp" data-wow-duration="1s" role="alert">
        <div class="p-4 d-flex flex-column">
            <h4 class="pb-20 text-center"><img class="img-responsive" src="assets/images/cookie.png" width="20"
                    alt="Cookie"> Do you like cookies?</h4>

            <p class="text-center">We use cookies to gather information on how our visitors use our website. That allows us to deliver you
                the best experience and to keep improving our website.</p>
            <p class="text-center">By continuing you agree to our <a [routerLink]="['/privacy-policy']">privacy policy</a>, including the
                usage of cookies.</p>
            <div class="ml-auto">
                <button type="button" class="btn btn-light" id="btnAccept" (click)="acceptCookie()">
                    Accept
                </button>
            </div>

        </div>
    </div>
</div>