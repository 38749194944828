import { Component, ViewEncapsulation, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { NgwWowService } from 'ngx-wow';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as Parse from 'parse';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Game Surv';

  constructor(@Inject(DOCUMENT) private _document, @Inject(PLATFORM_ID) private platformId: Object, private router: Router, private wowService: NgwWowService) {
     

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      if (isPlatformBrowser(this.platformId)) {
        window.scroll(0, 0);
        this.wowService.init();
      }
    });
  }

  ngOnInit() {
    // Add default styling for initial pages
    this._document.body.classList.remove('game-surv');
    this._document.body.classList.add('landing');
  }

  ngOnDestroy() {
    // remove the class form body tag
    // this._document.body.classList.remove('logix-intell');
  }
}
