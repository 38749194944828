
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { CountActionTypes, COMPLETE_COUNT, COMPLETE_COUNT_SUCCESS, COMPLETE_COUNT_FAILED, RESEND_EMAIL_RESULTS, RESEND_EMAIL_RESULTS_SUCCESS, RESEND_EMAIL_RESULTS_FAILED, PAID_FOR_COUNT_SUCCESS, PAID_FOR_COUNT_FAILED, PAID_FOR_COUNT } from '../actions/count.actions';
import { of, Observable, from } from 'rxjs';
import { AppState } from '../../models';
import { ParseServerProvider } from '../../providers/parse-server.provider';

@Injectable()
export class CountEffects {

    @Effect()
    completeCount$: Observable<Action> = this.actions$
        .pipe(
            ofType<COMPLETE_COUNT>(CountActionTypes.COMPLETE_COUNT),
            // withLatestFrom(this.store.select('app')),
            switchMap((action) => {
                return from(this.parseServerProvider.completeCount(action.payload.count_id)).pipe(
                    map(() => {
                        return new COMPLETE_COUNT_SUCCESS();
                    }),
                    catchError(err =>
                        of(new COMPLETE_COUNT_FAILED({ error: err.message }))
                    )
                );
            })
        );

        @Effect()
        paidForCount$: Observable<Action> = this.actions$
            .pipe(
                ofType<PAID_FOR_COUNT>(CountActionTypes.PAID_FOR_COUNT),
                switchMap((action) => {
                    return from(this.parseServerProvider.paidForCount(action.payload.countId)).pipe(
                        // takeUntil(this.subService.unsubscribe$),
                        map(() => {
                            // this.emailService.sendCountResults(action.payload.count_id);
                            return new PAID_FOR_COUNT_SUCCESS();
                        }),
                        catchError(err =>
                            of(new PAID_FOR_COUNT_FAILED({ error: err.message }))
                        )
                    );
                })
            );

    @Effect()
    resendCountEmail$: Observable<Action> = this.actions$
        .pipe(
            ofType<RESEND_EMAIL_RESULTS>(CountActionTypes.RESEND_EMAIL_RESULTS),
            switchMap((action) => {
                return from(this.parseServerProvider.resendResultsEmail(action.payload.farmId, action.payload.countId)).pipe(
                    // takeUntil(this.subService.unsubscribe$),
                    map(() => {
                        // this.emailService.sendCountResults(action.payload.count_id);
                        return new RESEND_EMAIL_RESULTS_SUCCESS();
                    }),
                    catchError(err =>
                        of(new RESEND_EMAIL_RESULTS_FAILED({ error: err.message }))
                    )
                );
            })
        );


    constructor(private actions$: Actions, private store: Store<AppState>, private parseServerProvider: ParseServerProvider) { }

}
