<div class="container">
    <div class="auth-card">
        <h1 class="text-uppercase">Sign in to</h1>
        <h1 class="text-uppercase pb-20"><span>GameSurv</span></h1>

        <form class="auth-form" [formGroup]="loginForm" (ngSubmit)="loginUser()" novalidate>
            <div class="input-group mb-3">
                <input type="email" formControlName="email" class="form-control" placeholder="Email" aria-label="Email"
                    aria-describedby="Email">
            </div>
            <small *ngIf="!loginForm.controls.email.valid && loginForm.controls.email.dirty" id="emailError"
                class="text-danger form-text">Please enter a valid email.</small>
            <div class="input-group mb-3">
                <input type="password" formControlName="password" class="form-control" placeholder="Password"
                    aria-label="Password" aria-describedby="Password">
            </div>
        </form>
        <p class="alt-text mb-10"><a [routerLink]="['/reset-password']">Forgot password?</a></p>
        <p class="alt-text mb-10">Don't have an account? <a [routerLink]="['/sign-up']">Sign up</a></p>
        <button type="button" class="btn btn-secondary mb-20" [routerLink]="['/']">
            Cancel
        </button>
        <button type="button" class="btn btn-primary mb-10" (click)="loginUser()"
            [disabled]="busyLoggingIn || loginForm.invalid">
            Login
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                *ngIf="busyLoggingIn"></span>
        </button>
        <p class="error-message" *ngIf="showError">{{error}}</p>
    </div>
</div>