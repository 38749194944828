import { Injectable } from '@angular/core';

@Injectable()
export class SidebarProvider {

  defaultBar = [
    {
      title: '',
      routerLink: '#',
      icon: 'icon-home',
      isSelected: false,
      isVisible: false,
      order: 0,
      subMenu: [
        {
          title: '',
          routerLink: '',
          isLabel: true
        }
      ]
    }
  ];

  sidebar = [
    {
      title: 'Dashboard',
      routerLink: '/gamesurv/dashboard',
      icon: 'uil-comment-chart-line',
      isSelected: false,
      isVisible: true,
      order: 0,
      subMenu: [
        {
          title: 'Dashboards',
          routerLink: '',
          icon: ''
        },
        {
          title: 'Overview',
          routerLink: '/gamesurv/dashboard',
          icon: 'uil-comment-chart-line'
        },
        {
          title: 'Employees',
          routerLink: '/gamesurv/users',
          icon: 'uil-chat-bubble-user'
        }
      ]
    },
    // {
    //   title: 'Posts',
    //   routerLink: '/posts',
    //   icon: 'uil-file',
    //   isSelected: false,
    //   isVisible: true,
    //   order: 0,
    //   subMenu: [
    //     {
    //       title: 'Posts',
    //       routerLink: '',
    //       icon: ''
    //     },
    //     {
    //       title: 'Posts',
    //       routerLink: '/posts',
    //       icon: 'uil-file'
    //     },
    //     {
    //       title: 'New Post',
    //       routerLink: '/posts/new',
    //       icon: 'uil-file-plus-alt'
    //     },
    //     {
    //       title: 'Categories',
    //       routerLink: '',
    //       icon: ''
    //     },
    //     {
    //       title: 'Categories',
    //       routerLink: '/posts/categories',
    //       icon: 'uil-list-ui-alt'
    //     },
    //     {
    //       title: 'Documents',
    //       routerLink: '',
    //       icon: ''
    //     },
    //     {
    //       title: 'Documents',
    //       routerLink: '/posts/documents',
    //       icon: 'uil-folder-upload'
    //     },
    //   ]
    // },
    // {
    //   title: 'Users',
    //   routerLink: '/users',
    //   icon: 'uil-users-alt',
    //   isSelected: false,
    //   isVisible: true,
    //   order: 0,
    //   subMenu: [
    //     {
    //       title: 'Manage Users',
    //       routerLink: '',
    //       icon: ''
    //     },
    //     {
    //       title: 'Users',
    //       routerLink: '/users',
    //       icon: 'uil-users-alt'
    //     }
    //   ]
    // },
    {
      title: 'Farms',
      routerLink: '/gamesurv/farms',
      icon: 'uil-images',
      isSelected: false,
      isVisible: true,
      order: 0,
    },
    {
      title: 'Counts',
      routerLink: '/gamesurv/counts',
      icon: 'uil-layers-alt',
      isSelected: false,
      isVisible: true,
      order: 0,
    },
    {
      title: 'Tracker',
      routerLink: '/gamesurv/tracker',
      icon: 'uil-crosshair',
      isSelected: false,
      isVisible: true,
      order: 0,
    },
    {
      title: 'Create Farm',
      routerLink: '/gamesurv/create-farm',
      icon: 'uil-trees',
      isSelected: false,
      isVisible: true,
      order: 0,
    },
    {
      title: 'How to',
      routerLink: '/gamesurv/how-to',
      icon: 'uil-question-circle',
      isSelected: false,
      isVisible: true,
      order: 0,
    }
  ];

  constructor() {

  }
}

